import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { GlobalContextProvider } from './context';
import TagManager from 'react-gtm-module';
import { BrowserRouter as Router } from 'react-router-dom';

if(process.env.REACT_APP_GOOGLE_TAG_MANAGER_KEY && process.env.REACT_APP_GOOGLE_TAG_MANAGER_KEY.length){
    const tagManagerArgs = {
      gtmId: process.env.REACT_APP_GOOGLE_TAG_MANAGER_KEY
    };
    TagManager.initialize(tagManagerArgs);
}
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<GlobalContextProvider><Router><App/></Router></GlobalContextProvider>);