import React, { useEffect, useRef, useState } from "react";
import Breadcrumbs from "../../components/BreadCrumbs";
import { getProducts } from "../../helper/backend-methods";
import { useParams } from "react-router-dom";
import Error404 from "../../components/Error404";
import Skeleton from "react-loading-skeleton";
import ProductCard from "../products/product-card";
import T from "../../locale/translations-main";
import "../../assets/css/--page-discounts.css";

function DiscountProducts(props) {
    const [ products, setProducts ] = useState([]);
    const [ totalProducts, setTotalProducts ] = useState(0);
    const [ loading, setLoading ] = useState(true);
    const [ error, setError ] = useState(false);
    const [ btnLoading, setBtnLoading ] = useState(false);
    const [ offset, setOffset ] = useState(0);
    const [ breadcrumbItems, setBreadcrumbItems ] = useState([ { title: "Home", link: "/" }, { title: "Discounts", link: "" } ]);
    const loader = useRef(false);


    useEffect(() => {
        const discountAttribute = localStorage.getItem("discountAttribute");
        if (discountAttribute && discountAttribute.length) {
            initializeListener();
            fetchProducts();
        }
        else{
            setLoading(false);
            setError(true);
        }
    }, [])

    useEffect(()=>{
        if(offset){
            setBtnLoading(true);
            fetchProducts(15, offset)
        }
    },[offset])

    const fetchProducts = (limit = 15, currentOffset = 0) => {
        const discountAttribute = localStorage.getItem("discountAttribute");
        getProducts({ sortBy: 0, limit: limit, offset: currentOffset, DiscountTag: discountAttribute }).then(({ data }) => {
            setTotalProducts(data.Data.ProductTotal)
            if(currentOffset === 0){
                if(data?.Data?.CampaignName){
                    setBreadcrumbItems([ { title: "Home", link: "/" }, { title: "Discounts", link: "" }, { title: `${data.Data.CampaignName} (${data.Data.ProductTotal})`, link: "" } ])
                }
                else{
                    setBreadcrumbItems([ { title: "Home", link: "/" }, { title: `Discounts (${data.Data.ProductTotal})`, link: "" } ])
                }
                setProducts(data.Data.Products)
            }
            else{
                setProducts((prev)=>[...prev,...data.Data.Products])
            }
            setBtnLoading(false);
            setLoading(false)
            loader.current = false;
        }).catch((e) => {
            console.log(e)
        })
    }

    const initializeListener = () => {
        window.addEventListener('scroll', function () {
            var targetElement = document.getElementById('loadMoreProducts'); // Replace with your element's ID
            if (targetElement) {
                var elementPosition = targetElement.getBoundingClientRect();
                if (elementPosition.top >= 0 && elementPosition.bottom <= window.innerHeight) {
                    if (!loader.current) {
                        loader.current = true;
                        loadMore();
                    }
                }
            }
        });
    }

    const loadMore = () => {
        setOffset((prev) => prev + 15);
    }

    return (
        loading ?
            <React.Fragment>
                <Breadcrumbs pageTile="Discounts" breadcrumbItems={breadcrumbItems} />
                <section id="discounts">
                    <div className="wrapper">
                        <div className="products-wrapper">
                            {
                                [...Array(15)].map((x, i) => (
                                    <div className="product-card p-0 border-none dummy" key={i}>
                                        <Skeleton height="460px" style={{ width: "100%" }} />
                                    </div>
                                ))
                            }
                        </div>
                    </div>
                </section>
            </React.Fragment> :
            <React.Fragment>
                <Breadcrumbs pageTile="Discounts" breadcrumbItems={breadcrumbItems} />
                <section id="discounts">
                    <div className="wrapper">
                        {
                            products.length ?
                            <div className={"products-wrapper"}>
                                {
                                    products.map((item,index)=>{
                                        return <ProductCard key={index} item={item} pageTitle="Product Listing" permissions={props.consent}/>
                                    })
                                }
                            </div> : 
                            <p className="btn btn-alternative d-flex align-items-center m-0 w-100"><i className="ri-information-line fs-22 mr-2"></i><T>No Products Found.</T></p>
                        }
                        {
                            totalProducts > products.length ?
                            <div className="products-pagination d-flex align-items-center justify-center mt-3">
                                {
                                    btnLoading ?
                                    <button className="btn btn-outline-primary" type="button"><T>Loading...</T></button> :
                                    <button className="btn btn-outline-primary" id="loadMoreProducts" type="button" onClick={loadMore}><T>Show More Products</T></button>
                                }
                            </div> : <></>
                        }
                    </div>
                </section>
            </React.Fragment>
    )
}
export default DiscountProducts;