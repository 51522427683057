import React, { useContext } from "react";
import { useNavigate  } from "react-router-dom";
import { postlogout } from "../../helper/backend-methods";
import T from "../../locale/translations-main";
import { GlobalContext } from "../../context/index"
function Logout() {
    const { updateCartCount } = useContext(GlobalContext);
    const history = useNavigate();
    setTimeout(()=>{
        postlogout().then((data)=>{
            localStorage.removeItem("authToken"); 
            localStorage.removeItem("guestToken"); 
            localStorage.removeItem("userinfo");
            localStorage.removeItem("identifier");
            localStorage.removeItem("cartItemCount");
            localStorage.removeItem("discountAttribute");
            localStorage.removeItem("enablePopup");
            localStorage.removeItem("discountProcessed");
            updateCartCount(0);
            setTimeout(()=>{
                history('/');
            },1000)
        })
    },1000)


    return (
        <section className="d-flex align-items-center justify-center">
            <p className="m-0 btn btn-alternative w-100"><T>Safely logging you out of your account. Please Wait...</T></p>
        </section>
    )
}

export default Logout;