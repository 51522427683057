const translations = {
  "Checkout": {
    "en": "Checkout",
    "ar": "الدفع"
  },
  "Following are all the checkout details.": {
    "en": "Following are all the checkout details.",
    "ar": "فيما يلي جميع تفاصيل الدفع والخروج."
  },
  "Loading checkout options. Please Wait...": {
    "en": "Loading checkout options. Please Wait...",
    "ar": "تحميل خيارات الخروج. انتظر من فضلك..."
  },
  "Personal Information": {
    "en": "Personal Information",
    "ar": "معلومات شخصية"
  },
  "Your Name": {
    "en": "Your Name",
    "ar": "اسمك"
  },
  "Phone Number": {
    "en": "Phone Number",
    "ar": "رقم الجوال"
  },
  "Email": {
    "en": "Email",
    "ar": "البريد الإلكتروني"
  },
  "Shipping Information": {
    "en": "Shipping Information",
    "ar": "معلومات الشحن"
  },
  "Use same billing address": {
    "en": "Use same billing address",
    "ar": "استخدم نفس عنوان الفواتير"
  },
  "Address": {
    "en": "Address",
    "ar": "العنوان"
  },
  "You currently have no addresses saved.": {
    "en": "You currently have no addresses saved.",
    "ar": "ليس لديك أي عناوين محفوظة."
  },
  "Create Now": {
    "en": "Create Now",
    "ar": "اضافة عنوان"
  },
  "Billing Information": {
    "en": "Billing Information",
    "ar": "معلومات الفواتير"
  },
  "Payment Method": {
    "en": "Payment Method",
    "ar": "طريقة الدفع "
  },
  "Pay cash on delivery": {
    "en": "Pay cash on delivery",
    "ar": "الدفع نقدا عند الاستلام"
  },
  "New Card": {
    "en": "New Card",
    "ar": "بطاقة جديدة"
  },
  "Please provide the following information": {
    "en": "Please provide the following information",
    "ar": "يرجى تقديم المعلومات التالية"
  },
  "Card number not valid": {
    "en": "Card number not valid",
    "ar": "رقم البطاقة غير صحيح"
  },
  "Card expiry not valid": {
    "en": "Card expiry not valid",
    "ar": "تاريخ انتهاء الصلاحية غير صالح"
  },
  "Card CVV/CVC not valid": {
    "en": "Card CVV/CVC not valid",
    "ar": "رمز الأمان غير صحيح"
  },
  "Save card for future purchases": {
    "en": "Save card for future purchases",
    "ar": "حفظ البطاقة للمشتريات المستقبلية"
  },
  "Shopping Cart": {
    "en": "Shopping Cart",
    "ar": "سلة التسوق"
  },
  "Sub Total:": {
    "en": "Sub Total:",
    "ar": "المجموع الفرعي: "
  },
  "SAR": {
    "en": "SAR",
    "ar": "ريال سعودي"
  },
  "Discount:": {
    "en": "Discount:",
    "ar": "الخصم"
  },
  "Shipping:": {
    "en": "Shipping:",
    "ar": "الشحن: "
  },
  "Tax": {
    "en": "Tax",
    "ar": "الضريبة"
  },
  "Total:": {
    "en": "Total:",
    "ar": "المجموع:"
  },
  "Please Wait...": {
    "en": "Please Wait...",
    "ar": "انتظر من فضلك..."
  },
  "Pay SAR": {
    "en": "Pay SAR",
    "ar": "الدفع"
  },
  "Payments are secure and encrypted": {
    "en": "Payments are secure and encrypted",
    "ar": "المدفوعات آمنة ومشفرة"
  },
  "Card CVV/CVC": {
    "en": "Card CVV/CVC",
    "ar": "رمز أمان البطاقة"
  },
  "Please provide your card CVV/CVC to proceed with your payment.": {
    "en": "Please provide your card CVV/CVC to proceed with your payment.",
    "ar": "يرجى تقديم رمز CVV / CVC لبطاقتك لمتابعة الدفع."
  },
  "Expires on ": {
    "en": "Expires on ",
    "ar": "تنتهي صلاحيته في"
  },
  "Finish Checkout": {
    "en": "Finish Checkout",
    "ar": "إنهاء الدفع "
  },
  "Please wait while your order is being processed": {
    "en": "Please wait while your order is being processed",
    "ar": "يرجى الانتظار بينما تتم معالجة طلبك"
  },
  "Thank you for Shopping": {
    "en": "Thank you for Shopping",
    "ar": "شكرا للتسوق"
  },
  "Here are your order details": {
    "en": "Here are your order details",
    "ar": "هنا تفاصيل طلبك"
  },
  "Order No": {
    "en": "Order No",
    "ar": "رقم الطلب"
  },
  "Expected Delivery": {
    "en": "Expected Delivery",
    "ar": "وقت التوصيل المتوقع"
  },
  "Total Amount": {
    "en": "Total Amount",
    "ar": "المبلغ الإجمالي"
  },
  "Shipping Address": {
    "en": "Shipping Address",
    "ar": "عنوان الشحن"
  },
  "Billing Addres": {
    "en": "Billing Addres",
    "ar": "عناوين الفواتير"
  },
  "Continue Shopping": {
    "en": "Continue Shopping",
    "ar": "متابعة التسوق"
  },
  "Track Order": {
    "en": "Track Order",
    "ar": "تتبع الطلب"
  },
  "Something went wrong. Please try again later.": {
    "en": "Something went wrong. Please try again later.",
    "ar": "هناك خطأ ما. الرجاء معاودة المحاولة في وقت لاحق."
  },
  "Order Failed!": {
    "en": "Order Failed!",
    "ar": "فشل الطلب!"
  },
  "Oops!": {
    "en": "Oops!",
    "ar": "أُووبس!"
  },
  "We apologize for the inconvenience but your order was canceled because your payment could not be processed. Please try again!": {
    "en": "We apologize for the inconvenience but your order was canceled because your payment could not be processed. Please try again!",
    "ar": "نأسف للإزعاج ولكن تم إلغاء طلبك بسبب تعذُّر معالجة الدفع. حاول مرة اخرى!"
  },
  "Use New Card": {
    "en": "Use New Card",
    "ar": "استخدم بطاقة جديدة"
  },
  "I agree to the": {
    "en": "I agree to the",
    "ar": "أنا أوافق على"
  },
  "Terms of Service": {
    "en": "Terms of Service",
    "ar": "شروط الخدمة"
  },
  "and": {
    "en": "and",
    "ar": "و"
  },
  "Privacy Policy": {
    "en": "Privacy Policy",
    "ar": "سياسة الخصوصية"
  },
  "Expires On ": {
    "en": "Expires On ",
    "ar": "تنتهي صلاحيته في"
  },
  "Don't know the address?": {
    "en": "Don't know the address?",
    "ar": "لا تعرف العنوان؟"
  },
  "If you don't know the address, we will contact the recipient for the address": {
    "en": "If you don't know the address, we will contact the recipient for the address",
    "ar": "إذا كنت لا تعرف العنوان ، فسنقوم بالاتصال بالمستلم للحصول على العنوان"
  },
  "Recipient First Name": {
    "en": "Recipient First Name",
    "ar": "الاسم الأول للمستلم"
  },
  "Recipient Last Name": {
    "en": "Recipient Last Name",
    "ar": "اسم الأخير للمستلم"
  },
  "Recipient Phone Number": {
    "en": "Recipient Phone Number",
    "ar": "رقم جوال المستلم"
  },
  "Please provide recipient's first name": {
    "en": "Please provide recipient's first name",
    "ar": "يرجى تقديم الاسم الأول للمستلم"
  },
  "Please provide recipient's last name": {
    "en": "Please provide recipient's last name",
    "ar": "يرجى تقديم الاسم الأخير للمستلم"
  },
  "Please provide recipient's phone number": {
    "en": "Please provide recipient's phone number",
    "ar": "يرجى تقديم رقم جوال المستلم"
  },
  "We will contact the recipient for the address": {
    "en": "We will contact the recipient for the address",
    "ar": "سوف نتصل بالمستلم للحصول على العنوان"
  },
  "Qty": {
    "en": "Qty",
    "ar": "الكمية"
  },
  "Out of Stock": {
    "en": "Out of Stock",
    "ar": "نفدت الكمية"
  },
  "Stock Available": {
    "en": "Stock Available",
    "ar": "المخزون المتوفر"
  },
  "One or more products are out of stock": {
    "en": "One or more products are out of stock",
    "ar": "واحد أو أكثر من المنتجات غير متوفر في المخزون"
  },
  "Already Purchased": {
    "en": "Already Purchased",
    "ar": "مباع"
  },
  "Inclusive of VAT": {
    "en": "Inclusive of VAT",
    "ar": "شامل ضريبة القيمة المضافة"
  },
  "Go to Cart": {
    "en": "Go to Cart",
    "ar": "اذهب إلى سلة التسوق"
  },
  "We apologize for the inconvenience but certain items in your shopping cart have surpassed the stock available. Please review your cart items and place your order again!": {
    "en": "We apologize for the inconvenience but certain items in your shopping cart have surpassed the stock available. Please review your cart items and place your order again!",
    "ar": "نعتذر عن الإزعاج ولكن بعض المنتجات في عربة التسوق الخاصة بك قد تجاوزت المخزون المتاح. يرجى مراجعة منتجات عربة التسوق الخاصة بك وتقديم طلبك مرة أخرى!"
  },
  "You currently have no products in your cart.": {
    "en": "You currently have no products in your cart.",
    "ar": "ليس لديك حاليا أي منتجات في سلة التسوق الخاصة بك."
  },
  "Full Name": {
    "en": "Full Name",
    "ar": "الاسم الكامل"
  },
  "Pay": {
    "en": "Pay",
    "ar": "الدفع"
  },
  "An occasion item was already purchased.": {
    "en": "An occasion item was already purchased.",
    "ar": "تم بالفعل شراء منتج المناسبة."
  },
  "VAT": {
    "en": "VAT",
    "ar": "ضريبة القيمة المضافة"
  },
  "Try Again": {
    "en": "Try Again!",
    "ar": "حاول ثانية!"
  },
  "Your account is not eligible for Tamara payments.": {
    "en": "Your account is not eligible for Tamara payments.",
    "ar": "حسابك غير مؤهل لمدفوعات تمارا."
  },
  "Learn More!": {
    "en": "Learn More!",
    "ar": "اعرف المزيد!"
  },
  "Pay with Tamara": {
    "en": "Pay with Tamara!",
    "ar": "ادفع بواسطة تمارا"
  },
  "Tamara": {
    "en": "Tamara",
    "ar": "تمارا"
  },
  "Tamara payments are disabled since it requires a complete shipping address.": {
    "en": "Tamara payments are disabled since it requires a complete shipping address.",
    "ar": "تم تعطيل مدفوعات تمارا لأنها تتطلب عنوان شحن كامل."
  },
  "Apple Pay": {
    "en": "Apple Pay",
    "ar": "أبل باي"
  },
  "Send as gift": {
    "en": "Send as gift",
    "ar": "إرسال كهدية"
  },
  "We will contact the recipient to find out the address": {
    "en": "We will contact the recipient to find out the address",
    "ar": "سنتواصل مع المستلم لمعرفة العنوان."
  },
  "You have to provide an email address to use Tamara": {
    "en": "You have to provide an email address to use Tamara",
    "ar": "يجب عليك تقديم عنوان بريد إلكتروني لاستخدام تمارا"
  }
}
export default translations