import React, { createContext, useState } from 'react';
const GlobalContext = createContext();

const GlobalContextProvider = ({ children }) => {
  const [cartCount, setCartCount] = useState(-1);
  const [brandList, setBrandList] = useState([]);
  const [categoryList, setCategoryList] = useState([]);
  const [searchResults, setSearchResults] = useState([]);
  const [firstHit, setFirstHit] = useState(false);

  const updateCategoryList = (value) => {
    setCategoryList(value)
  };
  const updateCartCount = (value) => {
    setCartCount(value)
  };
  const updateBrandList = (value) => {
    setBrandList(value)
  };
  const updateSearchResults = (value) => {
    setSearchResults(value)
  };
  const updateFirstHit = (value) => {
    setFirstHit(value)
  };

  return (
    <GlobalContext.Provider value={{ categoryList, cartCount, brandList, searchResults, firstHit, updateCategoryList, updateCartCount, updateBrandList, updateSearchResults, updateFirstHit }}>
      {children}
    </GlobalContext.Provider>
  );
};

export { GlobalContext, GlobalContextProvider };