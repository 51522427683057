import { Link } from "react-router-dom"
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import popupImage from "../../assets/images/popup-icon.png"
import "../../assets/css/--component-product-card.css"
import { useState, useEffect, useContext } from "react";
import { addCart } from "../../helper/backend-methods";
import noImage from "../../assets/images/no-image.jpg"
import warningIcon from "../../assets/images/failed-icon.png"
import { analyticsTrackEvent } from "../../components/Analytics"
import _NC from "../../components/Numbers"
import { GlobalContext } from "../../context/index";
import T from "../../locale/translations-main"


function ProductCard({item, pageTitle, permissions}){
    const { updateCartCount } = useContext(GlobalContext);
    const [popup, setPopup] = useState(false)
    const [props, setProps] = useState(null)
    const [loading, setLoading] = useState(true)
    const [loadingButton, setLoadingButton] = useState(false)
    const [popupConflict, setPopupConflict] = useState(false)

    useEffect(() => {
        const isAR = localStorage.getItem("LANG") && localStorage.getItem("LANG") === "ar" ?  true : false;
        setProps({ 
            CategoryName: isAR ? item.category_ar: item.category_en, 
            DiscountPercentage: item.discount_percent,
            InStock: item.inStock,
            DiscountPercentage: item.discount_percent,
            ManufacturerName: isAR ? item.manufacture_ar : item.manufacture_en,
            ManufacturerPicture: item.manufacture_image,
            Price: item.current_price,
            ProductId: item.id,
            ProductName: isAR ? item.productname_ar : item.productname_en,
            ProductPictures: item.picture_url,
            ProductSku: item.sku,
            Quantity: 1,
            Rating: item.review
         })
        setLoading(false)
    },[item])


    const addToCart = (product) => {
        const occasion = localStorage.getItem("occasion") ? parseInt(localStorage.getItem("occasion")) : 0
        const cartHasItems = localStorage.getItem("cartItemCount") ? parseInt(localStorage.getItem("cartItemCount")) > 0 ? true : false : false;
        setLoadingButton(true)
        // if cart is currently empty
        if(!cartHasItems){
            addCart({Quantity:1, ProductId : [props.ProductId]}).then(({data}) =>{
                if(data.Status){
                    analyticsTrackEvent("Added to Cart", { screen_name: pageTitle, product_id: props.ProductId, product_name: props.ProductName, brand: props.ManufacturerName, category: props.CategoryName, sku: props.ProductSku, price: props.Price, quantity: 1 },permissions && permissions.tracking)
                    updateCartCount(data.Data.CartItemCount);
                    localStorage.setItem("occasion",0)
                    localStorage.setItem("cartItemCount",1);
                    setPopup(true)
                }
                setLoadingButton(false)
            }).catch((e)=>{
                console.log(e);
                setLoadingButton(false)
            })
        }
        // if items already in cart don't belong to any occasion
        else if(occasion === 0){
            addCart({Quantity:1, ProductId : [props.ProductId]}).then(({data}) =>{
                if(data.Status){
                    analyticsTrackEvent("Added to Cart", { screen_name: pageTitle, product_id: props.ProductId, product_name: props.ProductName, brand: props.ManufacturerName, category: props.CategoryName, sku: props.ProductSku, price: props.Price, quantity: 1 },permissions && permissions.tracking)
                    updateCartCount(data.Data.CartItemCount);
                    localStorage.setItem("occasion",0)
                    localStorage.setItem("cartItemCount",1);
                    setPopup(true)
                }
                setLoadingButton(false)
            }).catch((e)=>{
                console.log(e);
                setLoadingButton(false)
            })
        }
        // if items already in cart belong to an occasion
        else{
            setPopupConflict(true);
            setLoadingButton(false)
        }
    }
    const addToCartAfterConfirmation = () => {
        setPopupConflict(false);
        addCart({Quantity:1, ProductId : [props.ProductId]}).then(({data}) =>{
            if(data.Status){
                analyticsTrackEvent("Added to Cart", { screen_name: pageTitle, product_id: props.ProductId, product_name: props.ProductName, brand: props.ManufacturerName, category: props.CategoryName, sku: props.ProductSku, price: props.Price, quantity: 1 },permissions && permissions.tracking)
                updateCartCount(data.Data.CartItemCount);
                localStorage.setItem("occasion",0)
                localStorage.setItem("cartItemCount",1);
                setPopup(true)
            }
            setLoadingButton(false)
        }).catch((e)=>{
            console.log(e);
            setLoadingButton(false)
        })
    }
      
    return (
        <div className="product-card">
            {
                loading ? <Skeleton height="460px" style={{width: "100%"}} /> :
                <>
                    {props.DiscountPercentage ? <span className="product-tag">{props.DiscountPercentage}% <T>Off</T></span> : <></>}
                    <div className="product-header">
                        <Link to={`/product/${props.ProductId}`} className="product-image">
                            <img src={ props.ProductPictures ? props.ProductPictures : noImage} onError={(e) => { e.target.onerror = null; e.target.src = noImage }} alt={props.ProductName} draggable={false} />
                        </Link>
                        { props.ManufacturerName && props.ManufacturerPicture ? <span className="product-brand">
                            <img src={props.ManufacturerPicture} alt={props.ManufacturerName} draggable={false}/>
                        </span> : <></> }
                    </div>
                    <div className="product-body">
                        <Link to={`/product/${props.ProductId}`} className="product-title" title={props.ProductName}>{props.ProductName.length > 34 ? props.ProductName.slice(0, 31) + "..." : props.ProductName}</Link>
                        <div className="product-price">
                            <span className="product-currency">SAR</span>
                            <span className="product-amount">{props.UnitPriceAmount ? (props.Price - props.UnitPriceAmount).toFixed(2) : props.Price.toFixed(2)}</span>
                            { props.UnitPriceAmount ? <span className="product-old-amount"><s className="text-primary">SAR {props.Price.toFixed(2)}</s></span> : <></> }
                        </div>
                        <div className="product-info">
                            <div className="product-rating-stars">
                                {
                                    [0,0,0,0,0].map((i,v)=>{
                                        return v <= (Math.floor(item.Rating)-1) ? <i className="ri-star-fill fs-14" style={{color: "rgb(255, 179, 0)"}} key={v}></i> : <i className="ri-star-line fs-14" style={{color: "rgb(255, 179, 0)"}} key={v}></i>
                                    })
                                }
                            </div>
                            { props.InStock ? <span className="product-stock"><T>In Stock</T></span> : "" }
                        </div>
                        {
                            props.InStock ?
                            <button onClick={()=>{ loadingButton ? console.log("Loading") : addToCart(props)}} type="button" className="product-action btn btn-primary">{ loadingButton ? <T>Please Wait</T> : <T>Add to Cart</T>}</button> :
                            <button type="button" className="product-action btn btn-outline-primary-disabled"><T>Out of Stock</T></button>
                        }               
                    </div>
                    <div className={popup ? "product-card-popup active" : "product-card-popup"}>
                        <div className="popup-wrapper">
                            <h2 className="popup-title">{props.ProductName.length > 40 ? props.ProductName.slice(0, 37) + "..." : props.ProductName}</h2>
                            <span className="popup-subheading"><T>successfully added to cart</T></span>
                            <div className="popup-image-container">
                                <img src={popupImage} alt="" />
                            </div>
                            <Link className="btn btn-primary" to="/cart"><T>Go to Cart</T></Link>
                            <button className="btn btn-outline-secondary" onClick={() => { setPopup(false) }}><T>Continue Shopping</T></button>
                        </div>
                    </div>
                    <div className={ popupConflict ? "product-card-popup active" : "product-card-popup"}>
                        <div className="popup-wrapper">
                            <div className="popup-image-container mb-1">
                                <img src={warningIcon} alt="warning" style={{ height: "140px", width: "140px"}} />
                            </div>
                            {
                                <span className="popup-subheading text-gray-500 fs-14 mt-4 mb-4"><T>You already have items associated with an occasion in your cart and you are about to add a product that doesn't belong to any occasion. This will clear all your current cart items.</T></span>                                
                            }
                            <div className="d-flex align-items-center justify-center">
                                <button className="btn btn-outline-secondary ml-1 mr-1 mb-2" onClick={()=>{setPopupConflict(false)}}><T>Cancel</T></button>
                                <button className="btn btn-primary ml-1 mr-1 mb-2" onClick={()=>{addToCartAfterConfirmation(item.ProductId)}}><T>Continue</T></button>
                            </div>
                        </div>
                    </div>
                </>
            }
        </div>
    )
}

export default ProductCard;