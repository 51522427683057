
import Login from '../pages/authentication/login'
import Register from '../pages/authentication/register'
import ResetPassword from '../pages/authentication/reset-password'
import ForgetPassword from '../pages/authentication/forgot-password'
import Logout from '../pages/account/logout'
import Home from '../pages/home/'
import Products from '../pages/products/products'
import Product from '../pages/products/product-single'
import Wishlist from '../pages/account/account-wishlist'
import Profile  from '../pages/account/account-profile'
import Review from '../pages/account/account-review'
import Address from '../pages/account/account-address'
import Orders from '../pages/account/account-orders'
import Cart from '../pages/cart/cart'
import PaymentMethod from '../pages/account/account-payment-method'
import Occasion from '../pages/account/account-occasion'
import Checkout from '../pages/checkout/checkout'
import ViewOccasions from '../pages/account/view-occasions'
import ViewInvitedOccasions from '../pages/account/view-invited-occasions'
import ViewOrderDetails from '../pages/account/view-order-details'
import PrivacyPolicy from '../pages/privacy-policy/privacy-policy'
import Invite from '../pages/invite/Invite'
import RefundPolicy from '../pages/refund-policy/refund-policy'
import TermsnConditions from '../pages/terms-and-conditions/terms-and-conditions'
import TermsOfUse from '../pages/terms-of-use/terms-of-use'
import Contact from '../pages/contact/contact'
import ViewOrderShipment from '../pages/account/view-order-shipment'
import ViewTrackShipment from '../pages/account/view-track-shipment'
import ViewReturns from '../pages/account/view-returns'
import ReturnDetails from '../pages/account/account-view-return-details'
import TrackReturn from '../pages/account/account-track-return'
import RemoveOrderItems from '../pages/account/account-remove-order-items'
import ReturnItemPolicy from '../pages/return-item-policy/return-item-policy'
import Categories from '../pages/categories'
import Brands from '../pages/brands'
import DiscountProducts from '../pages/discount'


const baseRoutes = [
    { path: "/login", element: Login },
    { path: "/register", element: Register },
    { path: "/reset-password/:token/:guid", element: ResetPassword },
    { path: "/forgot-password", element: ForgetPassword },
]
const protectedRoutes = [
    { path: "/account/wishlist", element: Wishlist },
    { path: "/account/profile", element: Profile },
    { path: "/account/review", element: Review },
    { path: "/account/addresses", element: Address },
    { path: "/account/orders", element: Orders },
    { path: "/account/orders/:id", element: ViewOrderDetails },
    { path: "/account/orders/shipments/:id", element: ViewOrderShipment },
    { path: "/account/orders/shipments/track-shipment/:orderID/:id", element: ViewTrackShipment },
    { path: "/account/returns", element: ViewReturns },
    { path: "/account/returns/return-details/:id", element: ReturnDetails },
    { path: "/account/returns/track-return/:id", element: TrackReturn },
    { path: "/account/orders/remove-order-items/:id", element: RemoveOrderItems },
    { path: "/account/payment-methods", element: PaymentMethod },
    { path: "/account/occasions", element: Occasion },
    { path: "/account/occasions/:id", element: ViewOccasions },
    { path: "/account/occasions/invite/:id", element: ViewInvitedOccasions },
    { path: "/logout", element: Logout },
]
const publicRoutes = [
	{ path: "/", element: Home, header: true, footer: true },
    { path: "/categories", element: Categories, header: true, footer: true },
    { path: "/brands", element: Brands, header: true, footer: true },
    { path: "/products", element: Products, header: true, footer: true },
    { path: "/product/:id", element: Product, header: true, footer: true },
    { path: "/discounts/", element: DiscountProducts, header: true, footer: true },
    { path: "/cart", element: Cart, header: true, footer: true },
    { path: "/checkout", element: Checkout, header: true, footer: true },
    { path: "/privacy-policy", element: PrivacyPolicy, header: true, footer: true },
    { path: "/return-policy", element: RefundPolicy, header: true, footer: true },
    { path: "/return-item-policy", element: ReturnItemPolicy, header: true, footer: true },
    { path: "/terms-of-use", element: TermsOfUse, header: true, footer: true },
    { path: "/privacy-policy/app", element: PrivacyPolicy, header: false, footer: false },
    { path: "/return-policy/app", element: RefundPolicy, header: false, footer: false },
    { path: "/return-item-policy/app", element: ReturnItemPolicy, header: false, footer: false },
    { path: "/terms-of-use/app", element: TermsOfUse, header: false, footer: false },
    // { path: "/terms-and-conditions", element: TermsnConditions },
    // { path: "/contact", element: Contact },
    { path: "/invite", element: Invite, header: true, footer: true },
	{ path: "/", exact: true, element: Home, header: true, footer: true }
]


export { protectedRoutes, publicRoutes, baseRoutes };