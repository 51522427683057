import { Link, useNavigate, useSearchParams } from "react-router-dom";
import React, { useState, useEffect, useRef } from 'react';
import "../../assets/css/--component-login.css"
import logo from "../../assets/images/logo.png";
import logoFromBG from "../../assets/images/login-form-bg.jpg";
import google from "../../assets/images/social-icons/google.png";
import facebook from "../../assets/images/social-icons/facebook.png";
import { postLogin, socialLogin, generateOTP, verifyOTP, assignUserPassword, app, postUpdateProfile } from "../../helper/backend-methods";
import translations from "../../data/language.json"
import { getAuth, GoogleAuthProvider, signInWithPopup } from "firebase/auth";
import { LoginSocialFacebook } from "reactjs-social-login";
import { analyticsIdentityEvent, analyticsPageEvent, tiktokIdentity } from "../../components/Analytics";
import { fetchAndActivate, getRemoteConfig, getValue } from "firebase/remote-config";
import { generateKey } from "../../helper/api-methods";
import appboy from 'appboy-web-sdk';
import T, { _T } from "../../locale/translations-main";

function SignIn(props) {
    const [search, setSearch] = useSearchParams();
    const [redirect, setRedirect] = useState(false);
    const [language, setLanguage] = useState("en")
    const [configLoading, setConfigLoading] = useState(false);
    const [config, setConfig] = useState({ Maintenance: false, EnableFacebookLogin: false, EnableGoogleLogin: false})
    const [loading, setLoading] = useState(false);
    const [values, setValues] = useState({ commonField: "", phoneField: "", otpField: new Array(4).fill(""), passwordField: "", confirmPasswordField: "", showPasswordField: false, showConfirmPasswordField: false, login: false, enableOTP: false, enablePassword: false, enableConfirmPassword: false, locked: false })
    const [countDown, setCountDown] = useState(0);
    const timer = useRef(null);
    const history = useNavigate();
    const otpBoxReference = useRef([]);
    const [errors, setErrors] = useState({ commonField: "", phoneField: "", otpField: "", passwordField: "", confirmPasswordField: "", serverError: "" })

    const changeLanguage = (lang) => {
        localStorage.setItem("LANG", lang);
        if (lang === "ar") {
            setLanguage("ar")
            document.getElementsByTagName("body")[0].classList.add("RTL")
        }
        else {
            setLanguage("en")
            document.getElementsByTagName("body")[0].classList.remove("RTL")
        }
    }
    
    const getRemoteConfiguration = async () =>{
        const firebaseApp = app;
        const remoteConfig = getRemoteConfig(firebaseApp);
        remoteConfig.settings.minimumFetchIntervalMillis = 5000;
        await fetchAndActivate(remoteConfig)
        return getValue(remoteConfig, process.env.REACT_APP_FIREBASE_REMOTE_CONFIG);
    }

    useEffect(()=>{
        const lang = localStorage.getItem("LANG")
        if(lang && lang.length){
            if(lang === "ar"){
                document.getElementsByTagName("body")[0].classList.add("RTL")
                setLanguage("ar");
            }
            else{
                setLanguage("en");
                localStorage.setItem("LANG","en")
            }
        }
        else{
            setLanguage("en");
            localStorage.setItem("LANG","en")
        }
        if(props.consent.page){
            analyticsPageEvent("Sign-In");
        }
        if (search) {
            if (search.get('redirect') && search.get('redirect') !== "") {
                setRedirect(search.get('redirect'))
            }
        }
        getRemoteConfiguration().then((response)=>{
            setConfig(JSON.parse(response._value))
            setConfigLoading(false)
        }).catch((e)=>{
            setConfigLoading(false)
        })
    },[])

    const handleOtpChange = (value, index) => {
        let newArr = [...values.otpField];
        newArr[index] = value;
        setValues((prev)=>({...prev, otpField: newArr}))
        if(value && index < 4-1){
          otpBoxReference.current[index + 1].focus()
        }
    }
    
    const handleBackspaceAndEnter = (e, index) => {
        if(e.key === "Backspace" && !e.target.value && index > 0){
          otpBoxReference.current[index - 1].focus()
          otpBoxReference.current[index - 1].select()
        }
        if(e.key === "Enter" && e.target.value && index < 4-1){
          otpBoxReference.current[index + 1].focus()
          otpBoxReference.current[index + 1].select()
        }
    }

    const startTimer = (t) => {
        let time = t
        timer.current = setInterval(()=>{
            time = time - 1;
            setCountDown(time);
            if(time === 0){
                clearTimer();
            }
        }, 1000)
    }

    const clearTimer = () => {
        clearInterval(timer.current);
    }

    const auth = getAuth(app);
    const provider = new GoogleAuthProvider();
    const signInWithGoogle = () => {
        setErrors({ commonField: "", phoneField: "", otpField: "", passwordField: "", confirmPasswordField: "", serverError: "" })
        signInWithPopup(auth, provider).then((result) => {
            socialLogin({ socialToken: result.user.accessToken }).then(({ data }) => {
                if (data.Status) {
                    analyticsIdentityEvent(data.Data.UserInfo.CustomerId,{email: data.Data.UserInfo.Email, username: data.Data.UserInfo.FullName, guid: data.Data.UserInfo.CustomerGuid})
                    localStorage.setItem("authToken", data.Data.Token.Access_Token)
                    localStorage.setItem("userinfo", JSON.stringify(data.Data.UserInfo))
                    tiktokIdentity({"email": data.Data.UserInfo.Email, "phone_number": "", "external_id": data.Data.UserInfo.CustomerId})
                    if (redirect) {
                        history(redirect);
                    }
                    else {
                        history('/');
                    }
                }
                else {
                    setErrors({ commonField: "", phoneField: "", otpField: "", passwordField: "", confirmPasswordField: "", serverError: data.Message })
                }
            }).catch((e) => {
                setErrors({ commonField: "", phoneField: "", otpField: "", passwordField: "", confirmPasswordField: "", serverError: <T>Unable to sign-in using Google.</T> })
            })
        }).catch((e) => {
            setErrors({ commonField: "", phoneField: "", otpField: "", passwordField: "", confirmPasswordField: "", serverError: <T>Unable to sign-in using Google.</T> })
        })

    }
    const signInWithFacebook = (response) => {
        setErrors({ commonField: "", phoneField: "", otpField: "", passwordField: "", confirmPasswordField: "", serverError: "" })
        const data = { "email": response.data.email, "Name": response.data.name, "Picture": response.data.picture.data.url, "signInProvider": response.provider }
        socialLogin({ data: data, decrypted: true , socialToken: null}).then(({ data }) => {
            if (data.Status) {
                analyticsIdentityEvent(data.Data.UserInfo.CustomerId,{email: data.Data.UserInfo.Email, username: data.Data.UserInfo.FullName, guid: data.Data.UserInfo.CustomerGuid})
                localStorage.setItem("authToken", data.Data.Token.Access_Token)
                localStorage.setItem("userinfo", JSON.stringify(data.Data.UserInfo))
                tiktokIdentity({"email": data.Data.UserInfo.Email, "phone_number": "", "external_id": data.Data.UserInfo.CustomerId})
                if (redirect) {
                    history(redirect);
                }
                else {
                    history('/');
                }
            }
            else {
                setErrors({ commonField: "", phoneField: "", otpField: "", passwordField: "", confirmPasswordField: "", serverError: data.Message })
            }
        }).catch((e) => {
            setErrors({ commonField: "", phoneField: "", otpField: "", passwordField: "", confirmPasswordField: "", serverError: <T>Unable to sign-in using Facebook.</T> })
        })
    }

    const tryLogin = (e) => {
        e.preventDefault();
        setLoading(true);
        let flag = false;
        const err = { commonField: "", phoneField: "", otpField: "", passwordField: "", confirmPasswordField: "", serverError: "" };
        const emailRegex = /^\w+([\.-]?\w+)*(\+\w+)?@\w+([\.-]?\w+)*(\.\w{2,10})+$/;
        let isEmail = true;
        if (!values || !values.commonField){
            err.commonField = <T>This field is required</T>;
            flag = true;
        }
        else{
            if (!isNaN(values.commonField)) {
                if (!values.commonField ) {
                    err.commonField = <T>This field is required</T>;
                    flag = true;
                }
                if (!values.commonField || values.commonField.replaceAll(/\s/g,'').length < 12 || values.commonField.replaceAll(/\s/g,'').length > 12 || !values.commonField.startsWith('9665') ) {
                    err.commonField = <T>Please provide a valid phone number</T>;
                    flag = true;
                }
                isEmail = false;
            }
            else if (!emailRegex.test(values.commonField)) {
                err.commonField = <T>Please provide a valid email address</T>;
                flag = true;
            }
        }
        if (!values || !values.passwordField || values.passwordField.replaceAll(/\s/g, '').length < 6) {
            err.passwordField = <T>Please provide your account password</T>;
            flag = true
        }
        setErrors(err);
        if (!flag) {
            postLogin({ Email: values.commonField, Password: values.passwordField }).then(({data}) => {
                if(data.Status){
                    analyticsIdentityEvent(data.Data.UserInfo.CustomerId,{email: data.Data.UserInfo.Email, username: data.Data.UserInfo.FullName, guid: data.Data.UserInfo.CustomerGuid})
                    localStorage.setItem("authToken", data.Data.Token.Access_Token)
                    localStorage.setItem("userinfo", JSON.stringify(data.Data.UserInfo))
                    tiktokIdentity({"email": data.Data.UserInfo.Email, "phone_number": isEmail ? "" : values.commonField, "external_id": data.Data.UserInfo.CustomerId})
                    appboy.changeUser(data.Data.UserInfo.CustomerGuid);
                    if(data.Data.UserInfo.FullName){
                        appboy.getUser().setFirstName(data.Data.UserInfo.FullName);
                    }
                    if(data.Data.UserInfo.Email){
                        appboy.getUser().setEmail(data.Data.UserInfo.Email);
                    }
                    if(data.Data.UserInfo.Phone){
                        appboy.getUser().setPhoneNumber(data.Data.UserInfo.Phone);
                    }
                    let discountAttribute = localStorage.getItem("discountAttribute");
                    let discountProcessed = localStorage.getItem("discountProcessed") === "true" ? true : false;
                    if(discountAttribute && discountAttribute.length && !discountProcessed){
                        postUpdateProfile({ CustomerTag: discountAttribute }).then(({ data }) => {
                            if(data.Status){
                                appboy.logCustomEvent(discountAttribute, { screen_name: "Login Page" ,locale: language === "ar" ? "ar-SA" : "en-US"});
                                localStorage.setItem("discountAttribute",discountAttribute);
                                localStorage.setItem("enablePopup",true);
                                localStorage.setItem("discountProcessed",true);
                            }
                            else{
                                localStorage.setItem("discountAttribute",discountAttribute);
                                localStorage.setItem("enablePopup",true);
                                localStorage.setItem("discountProcessed",false);
                            }
                            if (redirect) {
                                history(redirect);
                            }
                            else {
                                history('/');
                            }
                        }).catch((e)=>{
                            console.log("Unable to process discount code", e);
                            localStorage.setItem("discountAttribute",discountAttribute);
                            localStorage.setItem("enablePopup",true);
                            localStorage.setItem("discountProcessed",false);
                            if (redirect) {
                                history(redirect);
                            }
                            else {
                                history('/');
                            }
                        })
                    }
                    else{
                        if (redirect) {
                            history(redirect);
                        }
                        else {
                            history('/');
                        }
                    }
                }
                else {
                    setErrors({ commonField: "", phoneField: "", otpField: "", passwordField: "", confirmPasswordField: "", serverError: <T>Account email or phone or password is invalid.</T> })
                    setLoading(false)
                }
            }).catch((err)=>{
                setErrors({ commonField: "", phoneField: "", otpField: "", passwordField: "", confirmPasswordField: "", serverError: <T>Account email or phone or password is invalid.</T> })
                setLoading(false)
            })
        }
        else{
            setLoading(false)
        }

    }

    const tryRegistration = (e) => {
        e.preventDefault();
        setLoading(true);
        let flag = false;
        const err = { commonField: "", phoneField: "", otpField: "", passwordField: "", confirmPasswordField: "", serverError: "" };
        // if (!values || !values.phoneField || values.phoneField.replaceAll(/\s/g,'').length < 9 || values.phoneField.replaceAll(/\s/g,'').length > 12 ) {
        if (!values || !values.phoneField) {
            err.phoneField = <T>This field is required</T>;
            flag = true;
        }
        if (!values || !values.phoneField || values.phoneField.replaceAll(/\s/g,'').length < 12 || values.phoneField.replaceAll(/\s/g,'').length > 12 || !values.phoneField.startsWith('9665') ) {
            err.phoneField = <T>Please provide a valid phone number</T>;
            flag = true;
        }
        setErrors(err);
        if(!flag){
            generateOTP({phoneNumber: values.phoneField}).then(({data})=>{
                if (data.Status) {
                    if(data.StatusCode === 5001){
                        setValues((prev)=>({...prev,enableOTP: true}));
                        setCountDown(data.Data.RemainingTimeInSeconds);
                        setTimeout(() => {
                            startTimer(data.Data.RemainingTimeInSeconds);
                        }, 1000);
                    }
                    if(data.StatusCode === 5000){
                        setValues((prev)=>({...prev, commonField: prev.phoneField, enablePassword: true, enableConfirmPassword: false, login: true}));
                    }
                    if(data.StatusCode === 5002){
                        setValues((prev)=>({...prev,enablePassword: true, enableConfirmPassword: true, login: false}));
                    }
                }
                else{
                    if(data.StatusCode === 2021){
                        setValues((prev)=>({...prev,locked: true}));
                        setErrors({ commonField: "", phoneField: "", otpField: "", passwordField: "", confirmPasswordField: "", serverError: <T>Your account has been locked because too many attempts were made. Please contact support@clicktoys.com for assistance.</T> })
                    }
                    else{
                        setErrors({ commonField: "", phoneField: "", otpField: "", passwordField: "", confirmPasswordField: "", serverError: <T>Something went wrong. Please try again!</T> })
                    }
                }
                setLoading(false)
            }).catch((error)=>{
                setErrors({ commonField: "", phoneField: "", otpField: "", passwordField: "", confirmPasswordField: "", serverError: <T>Something went wrong. Please try again!</T> })
                setLoading(false)
            })
        }
        else{
            setLoading(false);
        }
    }

    const tryOTP = (e) => {
        e.preventDefault();
        setLoading(true);
        let flag = false;
        const err = { commonField: "", phoneField: "", otpField: "", passwordField: "", confirmPasswordField: "", serverError: "" };
        if(!values || !values.otpField || !values.otpField.length || !values.otpField[0] || !values.otpField[1] || !values.otpField[2] || !values.otpField[3]){
            err.otpField = _T("Please enter your four digit OTP");
            flag = true;
        }
        setErrors(err);
        if(!flag){
            verifyOTP({otp: values.otpField.join(""), phoneNumber: values.phoneField}).then(({data})=>{
                if(data.Status){
                    setValues((prev)=>({...prev, enablePassword: true, enableConfirmPassword: true }))
                    setLoading(false);
                }
                else{
                    if(data.StatusCode === 2022){
                        setErrors({ commonField: "", phoneField: "", otpField: "Your four digit OTP has expired", passwordField: "", confirmPasswordField: "", serverError: "" })
                    }
                    else if(data.StatusCode === 2021){
                        setValues((prev)=>({...prev, locked: true }))
                        setErrors({ commonField: "", phoneField: "", otpField: "", passwordField: "", confirmPasswordField: "", serverError: <T>Your account has been locked because too many attempts were made. Please contact support@clicktoys.com for assistance.</T> })
                    }
                    else{
                        setErrors({ commonField: "", phoneField: "", otpField: <T>Your four digit OTP is invalid</T>, passwordField: "", confirmPasswordField: "", serverError: "" })
                    }
                    setLoading(false);
                }
            }).catch(()=>{
                setErrors({ commonField: "", phoneField: "", otpField: <T>Your four digit OTP is invalid</T>, passwordField: "", confirmPasswordField: "", serverError: "" })
                setLoading(false);
            })
        }
        else{
            setLoading(false);
        }
    }

    const tryPassword = (e) => {
        e.preventDefault();
        setLoading(true);
        let flag = false;
        let err = { commonField: "", phoneField: "", otpField: "", passwordField: "", confirmPasswordField: "", serverError: "" };
        if (!values.passwordField || values.passwordField.replaceAll(/\s/g,'').length < 6) {
            err.passwordField = _T("Password must have at minimum 6 charaters");
            flag = true;
        }
        if (!values.confirmPasswordField || values.confirmPasswordField.replaceAll(/\s/g,'').length < 6) {
            err.confirmPasswordField = _T("Password must have at minimum 6 charaters");
            flag = true;
        }
        if (values.confirmPasswordField !== values.passwordField) {
            err.confirmPasswordField = _T("Passwords do not match");
            flag = true;
        }

        setErrors(err);
        if(!flag){
            assignUserPassword({ deviceId: generateKey(true), password: values.passwordField, phoneNumber: values.phoneField}).then(({data})=>{
                if(data.Status){
                    analyticsIdentityEvent(data.Data.UserInfo.CustomerId,{email: data.Data.UserInfo.Email, username: data.Data.UserInfo.FullName, guid: data.Data.UserInfo.CustomerGuid})
                    localStorage.setItem("authToken", data.Data.Token.Access_Token)
                    localStorage.setItem("userinfo", JSON.stringify(data.Data.UserInfo))
                    tiktokIdentity({"email": data.Data.UserInfo.Email, "phone_number": values.phoneField, "external_id": data.Data.UserInfo.CustomerId})
                    appboy.changeUser(data.Data.UserInfo.CustomerGuid);
                    if(data.Data.UserInfo.FullName){
                        appboy.getUser().setFirstName(data.Data.UserInfo.FullName);
                    }
                    if(data.Data.UserInfo.Email){
                        appboy.getUser().setEmail(data.Data.UserInfo.Email);
                    }
                    if(data.Data.UserInfo.Phone){
                        appboy.getUser().setPhoneNumber(data.Data.UserInfo.Phone);
                    }
                    let discountAttribute = localStorage.getItem("discountAttribute");
                    let discountProcessed = localStorage.getItem("discountProcessed") === "true" ? true : false;
                    if(discountAttribute && discountAttribute.length && !discountProcessed){
                        postUpdateProfile({ CustomerTag: discountAttribute }).then(({ data }) => {
                            if(data.Status){
                                appboy.logCustomEvent(discountAttribute, { screen_name: "Login Page" ,locale: language === "ar" ? "ar-SA" : "en-US"});
                                localStorage.setItem("discountAttribute",discountAttribute);
                                localStorage.setItem("enablePopup",true);
                                localStorage.setItem("discountProcessed",true);
                            }
                            else{
                                localStorage.setItem("discountAttribute",discountAttribute);
                                localStorage.setItem("enablePopup",true);
                                localStorage.setItem("discountProcessed",false);
                            }
                            if (redirect) {
                                history(redirect);
                            }
                            else {
                                history('/');
                            }
                        }).catch((e)=>{
                            console.log("Unable to process discount code", e);
                            localStorage.setItem("discountAttribute",discountAttribute);
                            localStorage.setItem("enablePopup",true);
                            localStorage.setItem("discountProcessed",false);
                            if (redirect) {
                                history(redirect);
                            }
                            else {
                                history('/');
                            }
                        })
                    }
                    else{
                        if (redirect) {
                            history(redirect);
                        }
                        else {
                            history('/');
                        }
                    }
                }
                else{
                    if(data.StatusCode === 2027){
                        setErrors({ commonField: "", phoneField: "", otpField: "", passwordField: "", confirmPasswordField: "", serverError: data.Message })
                    }
                    else{
                        setErrors({ commonField: "", phoneField: "", otpField: "", passwordField: "", confirmPasswordField: "", serverError: <T>Something went wrong. Please try again!</T> })
                    }
                    setLoading(false)
                }
            }).catch(()=>{
                setErrors({ commonField: "", phoneField: "", otpField: "", passwordField: "", confirmPasswordField: "", serverError: <T>Something went wrong. Please try again!</T> })
                setLoading(false)
            })
        }
        else{
            setLoading(false)
        }
    }

    const switchToRegister = () => {
        setErrors({ commonField: "", phoneField: "", otpField: "", passwordField: "", confirmPasswordField: "", serverError: "" })
        setValues({ commonField: "", phoneField: "", otpField: new Array(4).fill(""), passwordField: "", confirmPasswordField: "", showPasswordField: false, showConfirmPasswordField: false, login: false, enableOTP: false, enablePassword: false, enableConfirmPassword: false, locked: false })
    }

    const switchToLogin = () => {
        setErrors({ commonField: "", phoneField: "", otpField: "", passwordField: "", confirmPasswordField: "", serverError: "" })
        setValues({ commonField: "", phoneField: "", otpField: new Array(4).fill(""), passwordField: "", confirmPasswordField: "", showPasswordField: false, showConfirmPasswordField: false, login: true, enableOTP: false, enablePassword: false, enableConfirmPassword: false, locked: false })
    }


    return(

        <section id="login">
            <div className="wrapper" style={{ "width": "100%" }}>
                <div className="login-form-wrapper">
                    {
                        !values.locked?
                            values.login && !values.enablePassword?
                                <form onSubmit={tryLogin}>
                                    <Link to={"/"} className="go-back"><i className="ri-home-4-line"></i></Link>
                                    <img src={logo} alt="ClickToys" className="form-logo" />
                                    <h1 className="form-title"><T>Welcome</T></h1>
                                    <p className="form-description"><T>Witness a world filled with happiness and fun</T></p>
                                    <div className="form-group">
                                        <label htmlFor="commonField"><T>Email / Phone</T><span className="text-primary">*</span></label>
                                        <input id="commonField" type="text" name="commonField" onChange={(e)=>{setValues((prev)=>({...prev,commonField: e.target.value.replaceAll(/\s/g,'').slice(0,50)}))}} value={values.commonField} placeholder={_T("Email / Phone")} className="text-left" />
                                        {errors.commonField ? <span className="text-primary fs-12 fw-400">{errors.commonField}</span> : null}
                                    </div>
                                    <div className="form-group has-icon">
                                        <label htmlFor="passwordField"><T>Password</T> <span className="text-primary">*</span></label>
                                        <input id="passwordField" type={values.showPasswordField ? "text" : "password"} name="passwordField" onChange={(e)=>{setValues((prev)=>({...prev,passwordField: e.target.value.replaceAll(/\s/g,'').slice(0,30)}))}} value={values.passwordField} placeholder={_T("Enter Password")} className="text-left" />
                                        <button onClick={()=>{ setValues((prev)=>({...prev,showPasswordField: !prev.showPasswordField})) }} type="button" >{values.showPasswordField ? <i className="ri-eye-off-line"></i>:<i className="ri-eye-line"></i> }</button>
                                        {errors.passwordField ? <span className="text-primary fs-12 fw-400">{errors.passwordField}</span> : null}
                                    </div>
                                    <div className="form-additional d-flex align-items-center justify-between mb-3">
                                        <div className="">
                                            <input className="input-checkbox" type={"checkbox"} name="remember" id="remember" value={"true"} />
                                            <label htmlFor="remember" className="fs-14 fw-400 text-gray-500 mb-0"><T>Remember me</T></label>
                                        </div>
                                        <div className="">
                                            <Link to={"/forgot-password"} className="link fs-14"><T>Forgot Password?</T></Link>
                                        </div>
                                    </div>
                                    { errors.serverError ? <p className="text-center text-primary fs-14 fw-400">{errors.serverError}</p> : null }
                                    <div className="form-group">
                                        <button type="submit" disabled={loading} className={`btn btn-primary w-100 ${loading? "btn-disabled":""}`}>{loading ? translations.login[language].pleaseWait : <T>Sign-in to Continue</T> }</button>
                                    </div>
            
                                    {
                                        !configLoading && ( config.EnableFacebookLogin || config.EnableGoogleLogin )?
                                        <React.Fragment>
                                            <div className="form-group d-flex align-items-center justify-center">
                                                <span className="line-left"></span>
                                                <span className="fs-12 text-gray-300 fw-400"><T>or continue with</T></span>
                                                <span className="line-right"></span>
                                            </div>
                                            <div className="social-login-wrapper d-flex align-items-center justify-center">
                                                { config.EnableGoogleLogin ? <button type="button" className="social-login cursor-pointer" onClick={signInWithGoogle}><img alt="Google Sign-in" src={google} /></button> : null }
                                                { config.EnableFacebookLogin ? <LoginSocialFacebook appId={process.env.REACT_APP_FACEBOOK_APP_ID} onResolve={signInWithFacebook} onReject={(error) => { setErrors((prev)=>({...prev, serverError: <T>Unable to sign-in using Facebook.</T> }))} }><button type="button" className="social-login cursor-pointer" ><img alt="facebook Sign-in" src={facebook} /></button></LoginSocialFacebook> : null }
                                            </div>
                                        </React.Fragment> : null
                                    }
                                    { 
                                        !loading ? 
                                        <div className="form-group mb-0 fs-14 text-gray-500 text-center">
                                            {translations.login[language].registeredYet}
                                            <span onClick={switchToRegister} className="link fs-14 ml-2">{translations.login[language].createAccount}</span>
                                        </div> : null
                                    }
                                    <div className="form-group mt-5 text-center">
                                        {
                                            language === "ar" ?
                                            <span className="link fs-14 ml-2 text-en" onClick={() => changeLanguage("en")}>Switch to English</span> :
                                            <span className="link fs-14 ml-2 text-ar" onClick={() => changeLanguage("ar")}>التحول إلى اللغة العربية</span>
                                        }
                                    </div>
                                </form> :
                                values.login && values.enablePassword && !values.enableConfirmPassword?
                                    <form onSubmit={tryLogin}>
                                        <Link to={"/"} className="go-back"><i className="ri-home-4-line"></i></Link>
                                        <img src={logo} alt="ClickToys" className="form-logo" />
                                        <h1 className="form-title"><T>Welcome</T></h1>
                                        <p className="form-description"><T>Witness a world filled with happiness and fun</T></p>
                                        <div className="form-group">
                                            <label htmlFor="phoneField"><T>Phone</T><span className="text-primary">*</span></label>
                                            <input id="phoneField" type="tel" name="phoneField" onChange={(e)=>{}} readOnly={true} value={values.phoneField.slice(0,12)} placeholder="9665-XXXX-XXXX" className="text-left" />
                                            {errors.phoneField && <p className="error">{errors.phoneField}</p>}
                                        </div>
                                        <div className="form-group has-icon">
                                            <label htmlFor="passwordField"><T>Password</T><span className="text-primary">*</span></label>
                                            <input id="passwordField" type={values.showPasswordField ? "text" : "password"} name="passwordField" onChange={(e)=>{setValues((prev)=>({...prev,passwordField: e.target.value.replaceAll(/\s/g,'').slice(0,30)}))}} value={values.passwordField} placeholder={_T("Enter Password")} className="text-left" />
                                            <button onClick={()=>{ setValues((prev)=>({...prev,showPasswordField: !prev.showPasswordField})) }} type="button" >{values.showPasswordField ? <i className="ri-eye-off-line"></i>:<i className="ri-eye-line"></i> }</button>
                                            {errors.passwordField ? <span className="text-primary fs-12 fw-400">{errors.passwordField}</span> : null}
                                        </div>
                                        <div className="form-additional d-flex align-items-center justify-between mb-3">
                                            <div className="">
                                                <input className="input-checkbox" type={"checkbox"} name="remember" id="remember" value={"true"} />
                                                <label htmlFor="remember" className="fs-14 fw-400 text-gray-500 mb-0"><T>Remember me</T></label>
                                            </div>
                                            <div className="">
                                                <Link to={"/forgot-password"} className="link fs-14"><T>Forgot Password?</T></Link>
                                            </div>
                                        </div>
                                        { errors.serverError ? <p className="text-center text-primary fs-14 fw-400">{errors.serverError}</p> : null }
                                        <div className="form-group">
                                            <button type="submit" disabled={loading} className={`btn btn-primary w-100 ${loading? "btn-disabled":""}`}>{loading ? <T>Please Wait...</T> : <T>Sign-in to Continue</T> }</button>
                                        </div>
                
                                        {
                                            !configLoading && ( config.EnableFacebookLogin || config.EnableGoogleLogin )?
                                            <React.Fragment>
                                                <div className="form-group d-flex align-items-center justify-center">
                                                    <span className="line-left"></span>
                                                    <span className="fs-12 text-gray-300 fw-400"><T>or continue with</T></span>
                                                    <span className="line-right"></span>
                                                </div>
                                                <div className="social-login-wrapper d-flex align-items-center justify-center">
                                                    { config.EnableGoogleLogin ? <button type="button" className="social-login cursor-pointer" onClick={signInWithGoogle}><img alt="Google Sign-in" src={google} /></button> : null }
                                                    { config.EnableFacebookLogin ? <LoginSocialFacebook appId={process.env.REACT_APP_FACEBOOK_APP_ID} onResolve={signInWithFacebook} onReject={(error) => { setErrors((prev)=>({...prev, serverError: <T>Unable to sign-in using Facebook.</T> }))} }><button type="button" className="social-login cursor-pointer" ><img alt="facebook Sign-in" src={facebook} /></button></LoginSocialFacebook> : null }
                                                </div>
                                            </React.Fragment> : null
                                        }
                                        { 
                                            !loading ? 
                                            <div className="form-group mb-0 fs-14 text-gray-500 text-center">
                                                {translations.login[language].registeredYet}
                                                <span onClick={switchToRegister} className="link fs-14 ml-2">{translations.login[language].createAccount}</span>
                                            </div> : null
                                        }
                                        <div className="form-group mt-5 text-center">
                                            {
                                                language === "ar" ?
                                                <span className="link fs-14 ml-2 text-en" onClick={() => changeLanguage("en")}>Switch to English</span> :
                                                <span className="link fs-14 ml-2 text-ar" onClick={() => changeLanguage("ar")}>التحول إلى اللغة العربية</span>
                                            }
                                        </div>
                                    </form> :
                                    !values.enableOTP ?
                                        <form onSubmit={tryRegistration}>
                                            <Link to={"/"} className="go-back"><i className="ri-home-4-line"></i></Link>
                                            <img src={logo} alt="ClickToys" className="form-logo" />
                                            <h1 className="form-title"><T>Welcome</T></h1>
                                            <p className="form-description"><T>Witness a world filled with happiness and fun</T></p>
                                            <div className="form-group">
                                                <label htmlFor="phoneField"><T>Phone</T><span className="text-primary">*</span></label>
                                                <input id="phoneField" type="tel" name="phoneField" onChange={(e)=>{setValues((prev)=>({...prev,phoneField: e.target.value.replace(/\D/g, '').slice(0,14)}))}} value={values.phoneField} placeholder="9665-XXXX-XXXX" className="text-left" />
                                                {errors.phoneField ? <span className="text-primary fs-12 fw-400">{errors.phoneField}</span> : null}
                                            </div>
                                            <div className="form-additional d-flex align-items-center justify-between mb-3">
                                                <div className="">
                                                </div>
                                                <div className="">
                                                    <Link to={"/forgot-password"} className="link fs-14"><T>Forgot Password?</T></Link>
                                                </div>
                                            </div>

                                            { errors.serverError ? <p className="text-center text-primary fs-14 fw-400">{errors.serverError}</p> : null }
                                            <div className="form-group">
                                                <button type="submit" disabled={loading} className="btn btn-primary w-100">{loading ? <T>Please Wait...</T> : <T>Sign-in to Continue</T>}</button>
                                            </div>

                                            {
                                                !configLoading && ( config.EnableFacebookLogin || config.EnableGoogleLogin )?
                                                <React.Fragment>
                                                    <div className="form-group d-flex align-items-center justify-center">
                                                        <span className="line-left"></span>
                                                        <span className="fs-12 text-gray-300 fw-400"><T>or continue with</T></span>
                                                        <span className="line-right"></span>
                                                    </div>
                                                    <div className="social-login-wrapper d-flex align-items-center justify-center">
                                                        { config.EnableGoogleLogin ? <button type="button" className="social-login cursor-pointer" onClick={signInWithGoogle}><img alt="Google Sign-in" src={google} /></button> : null }
                                                        { config.EnableFacebookLogin ? <LoginSocialFacebook appId={process.env.REACT_APP_FACEBOOK_APP_ID} onResolve={signInWithFacebook} onReject={(error) => { setErrors((prev)=>({...prev, serverError: <T>Unable to sign-in using Facebook.</T> }))} }><button type="button" className="social-login cursor-pointer" ><img alt="facebook Sign-in" src={facebook} /></button></LoginSocialFacebook> : null }
                                                    </div>
                                                </React.Fragment> : null
                                            }
                                            { 
                                                !loading ?
                                                <div className="form-group mb-0 fs-14 text-gray-500 text-center">
                                                    <T>Already have an account? </T>
                                                    <span onClick={switchToLogin} className="link fs-14 ml-2"><T>Login</T></span>
                                                </div> : null
                                            }
                                            <div className="form-group mt-5 text-center">
                                                {
                                                    language === "ar" ?
                                                    <span className="link fs-14 ml-2 text-en" onClick={() => changeLanguage("en")}>Switch to English</span> :
                                                    <span className="link fs-14 ml-2 text-ar" onClick={() => changeLanguage("ar")}>التحول إلى اللغة العربية</span>
                                                }
                                            </div>
                                        </form> :
                                        !values.enablePassword && !values.enableConfirmPassword ?
                                            <form onSubmit={tryOTP}>
                                                <Link to={"/"} className="go-back"><i className="ri-home-4-line"></i></Link>
                                                <img src={logo} alt="ClickToys" className="form-logo" />
                                                <h1 className="form-title"><T>Welcome</T></h1>
                                                <p className="form-description"><T>Witness a world filled with happiness and fun</T></p>
                                                <p className="fs-14 fw-500 text-dark mb-0 text-center"><T>We have sent a four digit OTP to your provided mobile number ********</T> {values.phoneField.slice(-4)}.</p>
                                                <p className="fs-14 fw-500 text-dark mt-1 text-center"><T>Please enter it below to continue</T></p>
                                                <div className="form-group">
                                                    <div className="otp-fields">
                                                        {
                                                            values.otpField.map((digit, index)=>(
                                                                <input key={index} value={digit} maxLength={1} onChange={(e)=> handleOtpChange(e.target.value, index)} onKeyUp={(e)=> handleBackspaceAndEnter(e, index)} ref={(reference) => (otpBoxReference.current[index] = reference)} className="text-center"/>
                                                            ))
                                                        }
                                                    </div>
                                                    { errors.otpField ? <p className="text-primary fs-12 fw-400 text-center m-0">{errors.otpField}</p> : null }
                                                </div>

                                                {
                                                    countDown ?
                                                    <p className="text-gray-400 fs-14 fw-500 mt-0 mb-2 text-center"><T>Request OTP in</T> <span>{countDown}</span> <T>seconds</T></p> :
                                                    <p className="text-gray-400 fs-14 fw-500 mt-0 mb-2 text-center"><T>Didn't Recieve an OTP?</T> <span className="text-primary fw-500 cursor-pointer" onClick={()=>{ !loading ? tryRegistration({ preventDefault: ()=>{} }) : console.log(); }}><T>Request Now</T></span></p>
                                                }

                                                { errors.serverError ? <p className="text-center text-primary fs-14 fw-400">{errors.serverError}</p> : null }
                                                <div className="form-group">
                                                    <button type="submit" disabled={loading} className="btn btn-primary w-100">{loading ? <T>Please Wait...</T> : <T>Sign-in to Continue</T>}</button>
                                                </div>

                                                {
                                                    !configLoading && ( config.EnableFacebookLogin || config.EnableGoogleLogin )?
                                                    <React.Fragment>
                                                        <div className="form-group d-flex align-items-center justify-center">
                                                            <span className="line-left"></span>
                                                            <span className="fs-12 text-gray-300 fw-400"><T>or continue with</T></span>
                                                            <span className="line-right"></span>
                                                        </div>
                                                        <div className="social-login-wrapper d-flex align-items-center justify-center">
                                                            { config.EnableGoogleLogin ? <button type="button" className="social-login cursor-pointer" onClick={signInWithGoogle}><img alt="Google Sign-in" src={google} /></button> : null }
                                                            { config.EnableFacebookLogin ? <LoginSocialFacebook appId={process.env.REACT_APP_FACEBOOK_APP_ID} onResolve={signInWithFacebook} onReject={(error) => { setErrors((prev)=>({...prev, serverError: <T>Unable to sign-in using Facebook.</T> }))} }><button type="button" className="social-login cursor-pointer" ><img alt="facebook Sign-in" src={facebook} /></button></LoginSocialFacebook> : null }
                                                        </div>
                                                    </React.Fragment> : null
                                                }
                                                <div className="form-group mt-5 text-center">
                                                    {
                                                        language === "ar" ?
                                                        <span className="link fs-14 ml-2 text-en" onClick={() => changeLanguage("en")}>Switch to English</span> :
                                                        <span className="link fs-14 ml-2 text-ar" onClick={() => changeLanguage("ar")}>التحول إلى اللغة العربية</span>
                                                    }
                                                </div>
                                            </form> : 
                                            <form onSubmit={tryPassword}>
                                                <Link to={"/"} className="go-back"><i className="ri-home-4-line"></i></Link>
                                                <img src={logo} alt="ClickToys" className="form-logo" />
                                                <h1 className="form-title"><T>Welcome</T></h1>
                                                <p className="form-description"><T>Witness a world filled with happiness and fun</T></p>
                                                <div className="form-group has-icon">
                                                    <label htmlFor="passwordField"><T>Password</T> <span className="text-primary">*</span></label>
                                                    <input id="passwordField" type={values.showPasswordField ? "text" : "password"} name="passwordField" onChange={(e)=>{setValues((prev)=>({...prev,passwordField: e.target.value.replaceAll(/\s/g,'').slice(0,30)}))}}  value={values.passwordField} placeholder={_T("Enter Password")} />
                                                    <button onClick={()=>{ setValues((prev)=>({...prev,showPasswordField: !prev.showPasswordField})) }} type="button" >{values.showPasswordField ? <i className="ri-eye-off-line"></i>:<i className="ri-eye-line"></i> }</button>
                                                    {errors.passwordField ? <span className="text-primary fs-12 fw-400">{errors.passwordField}</span> : null}
                                                </div>
                                                <div className="form-group has-icon">
                                                    <label htmlFor="confirmPasswordField"><T>Confirm Password</T> <span className="text-primary">*</span></label>
                                                    <input id="confirmPasswordField" type={values.showConfirmPasswordField ? "text" : "password"} name="confirmPasswordField" onChange={(e)=>{setValues((prev)=>({...prev,confirmPasswordField: e.target.value.replaceAll(/\s/g,'').slice(0,30)}))}}  value={values.confirmPasswordField} placeholder={_T("Enter Password")} />
                                                    <button onClick={()=>{ setValues((prev)=>({...prev,showConfirmPasswordField: !prev.showConfirmPasswordField})) }} type="button" >{values.showPasswordField ? <i className="ri-eye-off-line"></i>:<i className="ri-eye-line"></i> }</button>
                                                    {errors.confirmPasswordField ? <span className="text-primary fs-12 fw-400">{errors.confirmPasswordField}</span> : null}
                                                </div>
                                                { errors.serverError ? <p className="text-center text-primary fs-14 fw-400">{errors.serverError}</p> : null }
                                                <div className="form-group">
                                                    <button type="submit" disabled={loading} className="btn btn-primary w-100">{loading ? <T>Please Wait...</T> : <T>Sign-in to Continue</T>}</button>
                                                </div>
                                                {
                                                    !configLoading && ( config.EnableFacebookLogin || config.EnableGoogleLogin )?
                                                    <React.Fragment>
                                                        <div className="form-group d-flex align-items-center justify-center">
                                                            <span className="line-left"></span>
                                                            <span className="fs-12 text-gray-300 fw-400"><T>or continue with</T></span>
                                                            <span className="line-right"></span>
                                                        </div>
                                                        <div className="social-login-wrapper d-flex align-items-center justify-center">
                                                            { config.EnableGoogleLogin ? <button type="button" className="social-login cursor-pointer" onClick={signInWithGoogle}><img alt="Google Sign-in" src={google} /></button> : null }
                                                            { config.EnableFacebookLogin ? <LoginSocialFacebook appId={process.env.REACT_APP_FACEBOOK_APP_ID} onResolve={signInWithFacebook} onReject={(error) => { setErrors((prev)=>({...prev, serverError: <T>Unable to sign-in using Facebook.</T> }))} }><button type="button" className="social-login cursor-pointer" ><img alt="facebook Sign-in" src={facebook} /></button></LoginSocialFacebook> : null }
                                                        </div>
                                                    </React.Fragment> : null
                                                }
                                                <div className="form-group mt-5 text-center">
                                                    {
                                                        language === "ar" ?
                                                        <span className="link fs-14 ml-2 text-en" onClick={() => changeLanguage("en")}>Switch to English</span> :
                                                        <span className="link fs-14 ml-2 text-ar" onClick={() => changeLanguage("ar")}>التحول إلى اللغة العربية</span>
                                                    }
                                                </div>
                                            </form> :
                            <form className="d-flex flex-column align-items-center justify-center">
                                <Link to={"/"} className="go-back"><i className="ri-home-4-line"></i></Link>
                                <img src={logo} alt="ClickToys" className="form-logo" />
                                <h1 className="form-title"><T>Account Locked</T></h1>
                                <p className="form-description"><T>Too Many Attempts Were Made</T></p>
                                { errors.serverError ? <p className="text-center text-primary fs-14 fw-400">{errors.serverError}</p> : null }
                            </form> 
                    }
                    <div className="login-background"><img src={logoFromBG} alt="" /></div>
                </div>
            </div>
        </section>
    )

}
export default SignIn;