import { Link, useParams } from "react-router-dom";
import React, {useState} from 'react'; 
import "../../assets/css/--component-login.css"
import logo from "../../assets/images/logo.png";
import logoFromBG from "../../assets/images/login-form-bg.jpg";
import { resetPassword } from "../../helper/backend-methods";
import { useEffect } from "react";
import { analyticsPageEvent } from "../../components/Analytics";
import translations from '../../data/language.json'
import T from "../../locale/translations-main";

function ResetPassword(props){

    let params = useParams();
    const token = params.token
    const guid = params.guid
    const [isVisible, setIsVisible] = useState(false)
    const [isVisible2, setIsVisible2] = useState(false)
    const [userpassword, setUserpassword] = useState("")
    const [userpassword2, setUserpassword2] = useState("")
    const [isSuccess, setIsSuccess] = useState(false)
    const [serverError, setServerError]= useState('');
    const [errors, setErrors] = useState({ userpassword: "", userpassword2: "" })
    const [loading, setLoading] = useState(false)
    const [content, setContent] = useState(translations.resetPassword["en"])

    useEffect(()=>{
        if(props.consent.page){
            analyticsPageEvent("Reset Password");
        }
        const lang = localStorage.getItem("LANG")
        if(lang && lang.length){
            setContent(lang === "ar" ? translations.resetPassword["ar"] : translations.resetPassword["en"])
        }
        window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    },[])


    useEffect(()=>{
        const lang = localStorage.getItem("LANG")
        if(lang && lang.length){
            if(lang === "ar"){
                document.getElementsByTagName("body")[0].classList.add("RTL")
            }
        }
    },[])


    const validateSubmit = (e) => {
        e.preventDefault();
        const errors = { userpassword: "", userpassword2: "" };
        let flag = false;
        if (!userpassword) {
            errors.userpassword = <T>Please provide a valid password</T>;
            flag = true;
        } 
        else if (userpassword.replaceAll(/\s/g,'').length < 6) {
            errors.userpassword = <T>Password must have at minimum 6 charaters</T>;
            flag = true;
        }
        if (!userpassword2) {
            errors.userpassword2 = <T>Please provide a valid password</T>;
            flag = true;
        } 
        else if (userpassword2.replaceAll(/\s/g,'').length < 6) {
            errors.userpassword2 = <T>Password must have at minimum 6 charaters</T>;
            flag = true;
        }
        else if(userpassword !== userpassword2){
            errors.userpassword2 = <T>Passwords do not match. Please try again!</T>;
            flag = true;
        }
        setErrors(errors);
        if(!flag){
            setLoading(true)
            resetPassword({token: token, guid: guid, password: userpassword}).then(({data})=>{
                if(data.Status){  
                    setServerError("Your password has been updated. Please sign-in to continue.")
                    setIsSuccess(true)
                }
                else{
                    setServerError("Something went wrong. Please try again!")
                    setLoading(false)
                }
            }).catch((e)=>{
                setServerError("Something went wrong. Please try again!")
                setLoading(false)
                console.log(e)
            })
            // add api function
        }
    }

    return (
        <section id="login">
            <div className="wrapper" style={{"width" : "100%"}}>
                <div className="login-form-wrapper">
                    <form onSubmit={validateSubmit}>
                        <img src={logo} alt="ClickToys" className="form-logo"/>
                        <h1 className="form-title"><T>Reset Password</T></h1>
                        <p className="form-description"><T>Choose a new password for your ClickToys account.</T></p>
                        <div className="form-group has-icon">
                            <label htmlFor="userpassword"><T>New Password</T><span className="text-primary">*</span></label>
                            <input readOnly={isSuccess} id="userpassword" type={isVisible ? "text" : "password"} name="userpassword" onChange={(e)=>{ setUserpassword(e.target.value.replaceAll(/\s/g,'').slice(0,30)) }}  value={userpassword} placeholder="●●●●●●●●" />
                            <button onClick={()=>{ setIsVisible(!isVisible) }} type="button" >{isVisible ? <i className="ri-eye-off-line"></i>:<i className="ri-eye-line"></i> }</button>
                            {errors.userpassword ? <span className="text-primary fs-12 fw-400">{errors.userpassword}</span> : null}
                        </div>
                        <div className="form-group has-icon">
                            <label htmlFor="userpassword2"><T>Confirm Password</T> <span className="text-primary">*</span></label>
                            <input readOnly={isSuccess} id="userpassword2" type={isVisible2 ? "text" : "password"} name="userpassword2" onChange={(e)=>{ setUserpassword2(e.target.value.replaceAll(/\s/g,'').slice(0,30)) }}  value={userpassword2} placeholder="●●●●●●●●" />
                            <button onClick={()=>{ setIsVisible2(!isVisible2) }} type="button" >{isVisible2 ? <i className="ri-eye-off-line"></i>:<i className="ri-eye-line"></i> }</button>
                            {errors.userpassword2 ? <span className="text-primary fs-12 fw-400">{errors.userpassword2}</span> : null}
                        </div>
                        {
                            serverError.length ? <p className="text-primary fs-14 fw-400"><T>{serverError}</T></p> : <></>
                        }
                        {
                            isSuccess ?
                            <div className="form-group">
                                <Link to={"/login"} className="btn btn-primary w-100"><T>Sign-In</T></Link>
                            </div> :
                            <div className="form-group">
                                <button type="submit" disabled={loading} className="btn btn-primary w-100">{ loading ? <T>Please Wait...</T> : <T>Update Password</T> }</button>
                            </div>
                        }
                    </form>
                    <div className="login-background">
                        <img src={logoFromBG} alt=""/>
                    </div>
                </div>
            </div>
        </section>
    )
};
export default ResetPassword;