const translations = {
        "Latest Toys": {
          "en": "Latest Toys",
          "ar": "أحدث الألعاب"
        },
        "Regardless of your child's age or preferred activity, we offer the ideal combination to keep their young minds occupied": {
          "en": "Regardless of your child's age or preferred activity, we offer the ideal combination to keep their young minds occupied",
          "ar": "بغض النظر عن عمر طفلك أو نشاطه المفضل ، فإننا نقدم مزيجًا مثاليًا لإبقاء عقولهم الصغيرة مشغولة"
        },
        "Shop Now": {
          "en": "Shop Now",
          "ar": "تسوق الآن"
        },
        "Boys Toys": {
          "en": "Boys Toys",
          "ar": "ألعاب الأولاد"
        },
        "Click Toys offers wide range of baby boy toys and for boys of any age": {
          "en": "Click Toys offers wide range of baby boy toys and for boys of any age",
          "ar": "يقدم كليك تويز مجموعة واسعة من ألعاب الأطفال والأولاد من أي عمر"
        },
        "Girls Toys": {
          "en": "Girls Toys",
          "ar": "ألعاب البنات"
        },
        "Our range of Barbie doll collections available online is perfect for both single players and groups of girls who enjoy caring for and sharing their dolls": {
          "en": "Our range of Barbie doll collections available online is perfect for both single players and groups of girls who enjoy caring for and sharing their dolls",
          "ar": "ممجموعات دمى الباربي المتوفرة عبر الإنترنت مثالية للفتيات اللواتي يحببن اللعب مفردا والفتيات اللواتي يستمتعن برعاية الدمى ومشاركتها."
        },
        "Featured": {
          "en": "Featured",
          "ar": "متميز"
        },
        "View some of our featured products": {
          "en": "View some of our featured products",
          "ar": "عرض بعض منتجاتنا المميزة"
        },
        "Shop By Category": {
          "en": "Shop By Category",
          "ar": "تسوق حسب الفئات"
        },
        "Find products based on a category": {
          "en": "Find products based on a category",
          "ar": "ابحث عن المنتجات على أساس الفئة"
        },
        "View All": {
          "en": "View All",
          "ar": "عرض الكل"
        },
        "Present Someone a gift?": {
          "en": "Present Someone a gift?",
          "ar": "تقديم هدية لشخص ما؟"
        },
        "On special Occasions let your hearty wishes be conveyed to your close and loved ones with this little token of love.You can create profile for your loved one's and present them gift's on their special Occasion with your choose of gift wrap.": {
          "en": "On special Occasions let your hearty wishes be conveyed to your close and loved ones with this little token of love.You can create profile for your loved one's and present them gift's on their special Occasion with your choose of gift wrap.",
          "ar": "في المناسبات الخاصة ، دع أمنياتك القلبية تنتقل إلى أحبائك من خلال هذه المبادرة. يمكنك إنشاء ملف تعريف لأحبائك وتقديم الهدايا لهم في مناسبتهم الخاصة مع اختيار تغليف الهدايا."
        },
        "Create Occasion": {
          "en": "Create Occasion",
          "ar": "انشاء مناسبة"
        },
        "Recently Added": {
          "en": "Recently Added",
          "ar": "الألعاب الجديدة"
        },
        "See our most latest products": {
          "en": "See our most latest products",
          "ar": "اكتشف أحدث منتجاتنا"
        },
        "Hot Selling": {
          "en": "Hot Selling",
          "ar": "الاكثر مبيعا"
        },
        "Find out some of our most selling products": {
          "en": "Find out some of our most selling products",
          "ar": "اكتشف بعض منتجاتنا الاكثر مبيعا"
        },
        "There are currently no categories": {
          "en": "There are currently no categories",
          "ar": "حاليًا لا توجد فئات."
        },
        "There are currently no brands": {
          "en": "There are currently no brands",
          "ar": "حاليًا لا توجد علامات تجارية."
        },
        "Find products based on a brand": {
          "en": "Find products based on a brand",
          "ar": "ابحث عن المنتجات بناءً على العلامة التجارية"
        },
        "Exclusive Offer Just for You!": {
          "en": "Exclusive Offer Just for You!",
          "ar": "عرض حصري فقط لك!"
        },
        "You've unlocked special discounted prices on select products.": {
          "en": "You've unlocked special discounted prices on select products.",
          "ar": "لقد حصلت على أسعار مخفضة خاصة على منتجات مختارة."
        },
        "Don't Show Again": {
          "en": "Don't Show Again",
          "ar": "لا تظهر هذا مرة أخرى"
        }
        
}
export default translations