import React from "react";
import { useState,useEffect } from "react";
import "react-multi-carousel/lib/styles.css";
import FeaturedProducts from "./featured-products";
import HomeBanner from "./home-banner";
import HotSelling from "./hot-selling";
import NewlyAdded from "./newly-added";
import OfferBanners from "./offer-banners";
import PresentGift from "./present-gift";
import ShopByCategories from "./shop-by-categories";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { getBanners, getOccasionDetails, getTaggedProducts, postUpdateProfile, setOccasionInvite, verifyDiscount } from "../../helper/backend-methods";
import { analyticsPageEvent } from "../../components/Analytics";
import T from "../../locale/translations-main";
import appboy from 'appboy-web-sdk';
import discountImage from "../../assets/images/presentgift.png"


function Home(props){
    const [search, setSearch] = useSearchParams();
    const [discountPopup, setDiscountPopup] = useState(false);
    const [occasionId, setOccasionId] = useState(0);
    const [occasion, setOccasion] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);
    const history = useNavigate();

    
    useEffect(()=>{
        const lang = localStorage.getItem("LANG")
        if (lang && lang.length) {
            document.title = lang === "ar" ? "كليك تويز" : "ClickToys"
        }
        else{
            document.title = "ClickToys"
        }
        window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
        if(search && search.get('reset')){
            localStorage.removeItem("authToken")
            localStorage.removeItem("guestToken")
            localStorage.removeItem("identifier")
            history('/');
        }
    },[])

    useEffect(()=>{
        const accessToken = localStorage.getItem("authToken")
        const isAuthenticated = accessToken && accessToken.length ? true : false;
        if(isAuthenticated){
            if(search){
                if(search.get('occasion_id')){
                    setOccasionId(search.get('occasion_id'))
                    getOccasionDetails({occasionId: search.get('occasion_id'), invite: true , accepted : false , check : false}).then(({data})=>{
                        if(data.Status){
                            var now = new Date(data.Data.Occasion.EventDateTimestamp * 1000);
                            now = new Date(now.setMinutes(now.getMinutes() + now.getTimezoneOffset()));
                            var year = now.getFullYear();
                            var month = now.getMonth() + 1;
                            var day = now.getDate();
                            var EventDateTimestamp  = (day < 10 ? "0" + day.toString() : day) + "-" + (month < 10 ? "0" + month.toString() : month) + "-" + year + "";
                            setOccasion({ ...data.Data.Occasion, EventDateTimestamp: EventDateTimestamp})
                        }
                        else{
                            history('/account/occasions/');
                            setError(true)
                        }
                        setLoading(false)
                    }).catch((e)=>{
                        setError(true)
                        history('/account/occasions/');
                    })
                }
                else if(search.get("orderNo")){
                    history('/account/orders/'+search.get("orderNo"));
                }
                else if(search.get("returnOrderId")){
                    history('/account/returns/return-details/'+search.get("returnOrderId"));
                }
                else if(search.get("shipment_order_id")){
                    history('/account/orders/shipments/'+search.get("shipment_order_id"));
                }
                else if(search.get("deep_link_value")){
                    setLoading(false);
                    setError(true);
                    verifyDiscount({discountTag: search.get("deep_link_value")}).then(({data})=>{
                        if(data.Data){
                            let oldDiscountAttribute = localStorage.getItem("discountAttribute") && localStorage.getItem("discountAttribute").length ? localStorage.getItem("discountAttribute") : search.get("deep_link_value");
                            let discountAttribute = search.get("deep_link_value");
                            let discountProcessed = oldDiscountAttribute === discountAttribute ? localStorage.getItem("discountProcessed") === "true" ? true : false : false;
                            if(!discountProcessed){
                                processDiscountCode(discountAttribute);
                            }
                            else{
                                localStorage.setItem("discountAttribute",discountAttribute);
                                localStorage.setItem("enablePopup",true);
                                localStorage.setItem("discountProcessed",false);
                                setDiscountPopup(true);
                            }
                        }
                    }).catch(()=>{
                        console.log("Discount attribute not valid")
                    })
                }
                else{
                    setError(true)
                    setLoading(false)
                }
            }
            else{
                setError(true)
                setLoading(false)
            }
        }
        else{
            if(search.get('occasion_id')){
                history('/login/?redirect='+encodeURI('/'+window.location.search));
            }
            else if(search.get("deep_link_value") && search.get("deep_link_value").length){
                verifyDiscount({discountTag: search.get("deep_link_value")}).then(({data})=>{
                    if(data.Data){
                        localStorage.setItem("discountAttribute",search.get("deep_link_value"));
                        localStorage.setItem("enablePopup",true);
                        localStorage.setItem("discountProcessed",false);
                    }
                }).catch(()=>{
                    console.log("Discount attribute not valid")
                })
                setLoading(false);
                setError(true);
            }
            else{
                setError(true)
                setLoading(false)
            }
        }
    },[search])

    useEffect(()=>{
        window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
        if(props.consent.page){
            analyticsPageEvent("Home");
        }
    },[props.consent])


    useEffect(()=>{
        fetchPageBanners();
        fetchTaggedProducts();
        toggleDiscountPopup();
    },[])

    const handleAccept = () => {
        setOccasionInvite({occasionId: occasionId, accepted: true }).then(({data})=>{
            if(data.Status){
                history('/account/occasions/invite/'+occasionId);
            }
            else{
                history('/account/occasions/');
            }
        }).catch((e)=>{
            history('/account/occasions/');
            console.log(e)
        })
    }
    const handleReject = () => {
        history('/');
    }

    const processDiscountCode = (discountAttribute) => {
        let locale = localStorage.getItem("LANG")
        if (locale && locale.length) {
            locale = locale === "ar" ? "ar-SA" : "en-US"
        }
        postUpdateProfile({ CustomerTag: discountAttribute }).then(({ data }) => {
            if(data.Status){
                appboy.logCustomEvent(discountAttribute, { screen_name: "Home Page" ,locale: locale ? locale : "en-US"});
                localStorage.setItem("discountAttribute",discountAttribute);
                localStorage.setItem("enablePopup",true);
                localStorage.setItem("discountProcessed",true);
                setDiscountPopup(true);
            }
            else{
                localStorage.setItem("discountAttribute",discountAttribute);
                localStorage.setItem("enablePopup",true);
                localStorage.setItem("discountProcessed",false);
            }
        }).catch((e)=>{
            console.log("Unable to process discount code", e);
            localStorage.setItem("discountAttribute",discountAttribute);
            localStorage.setItem("enablePopup",true);
            localStorage.setItem("discountProcessed",false);
        })
    }

    const toggleDiscountPopup = () => {
        const accessToken = localStorage.getItem("authToken")
        const isAuthenticated = accessToken && accessToken.length ? true : false;
        if(isAuthenticated){
            let query = search && search.get("deep_link_value") ? true : false;
            if(!query){ // only work if there is not matching query parameter
                let locale = localStorage.getItem("LANG")
                let discountAttribute = localStorage.getItem("discountAttribute");
                let enablePopup = localStorage.getItem("enablePopup") === "true" ? true : false;
                let discountProcessed = localStorage.getItem("discountProcessed") === "true" ? true : false;
                verifyDiscount({discountTag: discountAttribute}).then(({data})=>{
                    if(data.Data){
                        if (locale && locale.length) {
                            locale = locale === "ar" ? "ar-SA" : "en-US"
                        }
                        if(enablePopup){
                            if(discountProcessed){
                                setDiscountPopup(true);
                            }
                            else{
                                postUpdateProfile({ CustomerTag: discountAttribute }).then(({ data }) => {
                                    if(data.Status){
                                        appboy.logCustomEvent(discountAttribute, { screen_name: "Home Page" ,locale: locale ? locale : "en-US"});
                                        localStorage.setItem("discountAttribute",discountAttribute);
                                        localStorage.setItem("enablePopup",true);
                                        localStorage.setItem("discountProcessed",true);
                                        setDiscountPopup(true);
                                    }
                                    else{
                                        localStorage.setItem("discountAttribute",discountAttribute);
                                        localStorage.setItem("enablePopup",true);
                                        localStorage.setItem("discountProcessed",false);
                                    }
                                }).catch((e)=>{
                                    console.log("Unable to process discount code", e);
                                    localStorage.setItem("discountAttribute",discountAttribute);
                                    localStorage.setItem("enablePopup",true);
                                    localStorage.setItem("discountProcessed",false);
                                })
                            }
                        }
                    }
                }).catch(()=>{
                    console.log("Discount attribute not valid")
                })
            }
        }
    }

    const disablePopup = () => {
        setDiscountPopup(false);
        localStorage.setItem("enablePopup",false);
    }

    const [bannerData, setBannerData] = useState({home: [], homeSlides: [], featured: [], recent: [], offers: []});
    const [bannerLoading, setBannerLoading] = useState(true);
    const [taggedProducts, setTaggedProducts] = useState([]);
    const [taggedProductsLoading, setTaggedProductsLoading] = useState(true);

    const fetchPageBanners = () => {
        getBanners().then(({data})=>{
            if(data.Status){
                const home = data.Data.find((i)=> i.Key === "hero_banner");
                const homeSlides = data.Data.find((i)=> i.Key === "hero_slider_web");
                const featured = data.Data.find((i)=> i.Key === "featured_banner");
                const recent = data.Data.find((i)=> i.Key === "recently_added_banner");
                const offers = data.Data.find((i)=> i.Key === "promo_slider");
                const banners = {home: [], homeSlides: [], featured: null, recent: [], offers: []};
                if(home  && home.Slides.length){
                    banners.home = home.Slides;
                }
                if(homeSlides  && homeSlides.Slides.length){
                    banners.homeSlides = homeSlides.Slides;
                }
                if(featured && featured.Slides.length){
                    banners.featured = featured.Slides[0];
                }
                if(recent && recent.Slides.length){
                    banners.recent = recent.Slides[0];
                }
                if(offers && offers.Slides.length){
                    banners.offers = offers.Slides;
                }
                setBannerData(banners);
            }
            setBannerLoading(false)
        }).catch((e)=>{
            setBannerLoading(false)
            console.log(e)
        })
    }

    const fetchTaggedProducts = () => {
        getTaggedProducts({tagProductsLimit: 8}).then(({data})=>{
            if(data.Status){
                let tagged = data.Data.filter((t)=>t.Products && t.Products.length);
                setTaggedProducts(tagged);
            }
            setTaggedProductsLoading(false);
        }).catch((e)=>{
            console.log(e)
            setTaggedProductsLoading(false);
        })
    }


    return (
        <div className="wrapper">
            <HomeBanner permissions={props.consent} banners={bannerData} bannerLoading={bannerLoading}/>
            <ShopByCategories/>
            <FeaturedProducts consent={props.consent} products={taggedProducts[0] ? taggedProducts[0] : null} loading={taggedProductsLoading} banners={bannerData} bannerLoading={bannerLoading}/>
            <PresentGift/>
            <HotSelling consent={props.consent} products={taggedProducts[1] ? taggedProducts[1] : null} loading={taggedProductsLoading}  banners={bannerData} bannerLoading={bannerLoading}/>
            <OfferBanners consent={props.consent} banners={bannerData} bannerLoading={bannerLoading}/>
            <NewlyAdded consent={props.consent} banners={bannerData} bannerLoading={bannerLoading}/>
            <HotSelling consent={props.consent} products={taggedProducts[2] ? taggedProducts[2] : null} loading={taggedProductsLoading}  banners={bannerData} bannerLoading={bannerLoading}/>
            <HotSelling consent={props.consent} products={taggedProducts[3] ? taggedProducts[3] : null} loading={taggedProductsLoading}  banners={bannerData} bannerLoading={bannerLoading}/>
            <HotSelling consent={props.consent} products={taggedProducts[4] ? taggedProducts[4] : null} loading={taggedProductsLoading}  banners={bannerData} bannerLoading={bannerLoading}/>
            <HotSelling consent={props.consent} products={taggedProducts[5] ? taggedProducts[5] : null} loading={taggedProductsLoading}  banners={bannerData} bannerLoading={bannerLoading}/>

            {
                loading ?
                <section id="checkout" style={{position: "fixed", width: "100%", height: "100%", top: "0px", left: "0px", display: "flex", alignItems: "center", justifyContent: "center", zIndex: "999", background: "#fff", opacity: "0.8"}}>
                    <div className="wrapper">
                        <div className="payment-loading text-center">
                            <div className="payment-loading-spinner"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
                        </div>
                    </div>
                </section> : error ?
                <></> : 
                <section id="occasionInvitation">
                    <div className="invitation-wrapper">
                        <div className="invitation-avatar">
                            <img src={occasion.Picture} alt=""/>
                        </div>
                        <div className="invitation-title">
                            <h3 className="fs-16 fw-500 text-secondary mb-0">{occasion.Title}</h3>
                                <h3 className="fs-16 fw-500 text-gray-500 mt-0">{occasion.EventDateTimestamp}</h3>           
                        </div>
                        <div className="invitation-title">
                            <h3 className="fs-16 fw-500 text-gray-500"><T>Invited by</T> <span className="fs-16 fw-500 text-primary">{occasion.FullName}</span></h3>
                        </div>
                        <div className="d-flex align-items-center justify-center w-100 flex-wrap">
                            <button className="btn btn-outline-secondary ml-1 mr-1" onClick={()=>{ handleReject() }}><T>Reject</T></button>
                            <button className="btn btn-primary ml-1 mr-1" onClick={()=>{ handleAccept() }}><T>Accept</T></button>
                        </div>
                    </div>
                </section>
            }

            { discountPopup && (
                <div className="modal-overlay">
                    <div className="modal" style={{maxWidth: "400px"}}>
                        <div className="modal-header">
                            <a href="#" className="close-icon" onClick={()=>{ setDiscountPopup(false) }}>
                                <i className="ri-close-line"></i>
                            </a>
                        </div>
                        <div className="modal-content">
                            <div className="modal-card">
                                <img src={discountImage} alt="" style={{ margin: "0 auto", display: "block", maxWidth: "200px", width: "100%"}}/>
                                <T tagName="h2" className="text-gray-500 fs-16 text-center fw-500">Exclusive Offer Just for You!</T>
                                <T tagName="p" className="text-gray-400 fs-14 text-center fw-400">You've unlocked special discounted prices on select products.</T>
                                <Link to={`/discounts`} className="btn btn-primary w-100 mb-2"><T>Shop Now</T></Link>
                                <button className="btn btn-outline-primary w-100" onClick={disablePopup}><T>Don't Show Again</T></button>
                            </div>
                        </div>
                    </div>
                </div> 
            )}
        </div>
    )
}
export default Home;