const translations = {
    "Welcome": {
      "en": "Welcome",
      "ar": "مرحباً"
    },
    "Witness a world filled with happiness and fun": {
      "en": "Witness a world filled with happiness and fun",
      "ar": "عالم مليئ بالسعادة والمرح"
    },
    "Email": {
      "en": "Email",
      "ar": "البريد الإلكتروني"
    },
    "Password": {
      "en": "Password",
      "ar": "كلمة المرور"
    },
    "Enter Password": {
      "en": "Enter Password",
      "ar": "أدخل كلمة المرور"
    },
    "Remember me": {
      "en": "Remember me",
      "ar": "تذكرنى"
    },
    "Forgot Password?": {
      "en": "Forgot Password?",
      "ar": "هل نسيت كلمة السر؟"
    },
    "Please Wait...": {
      "en": "Please Wait...",
      "ar": "انتظر من فضلك..."
    },
    "Sign-in to Continue": {
      "en": "Sign-in to Continue",
      "ar": "تسجيل الدخول للمتابعة"
    },
    "Not registered yet?": {
      "en": "Not registered yet?",
      "ar": "لم يتم التسجيل بعد؟"
    },
    "Create an account": {
      "en": "Create an account",
      "ar": "إنشاء حساب"
    },
    "Please provide a valid email address": {
      "en": "Please provide a valid email address",
      "ar": "يرجى تقديم عنوان بريد إلكتروني صالح"
    },
    "Please provide your account password": {
      "en": "Please provide your account password",
      "ar": "يرجى تقديم كلمة مرور"
    },
    "or continue with": {
      "en": "or continue with",
      "ar": "أو المتابعة مع"
    },
    "Unable to sign-in using Facebook.": {
      "en": "Unable to sign-in using Facebook.",
      "ar": "تعذر تسجيل الدخول باستخدام Facebook."
    },
    "Unable to sign-in using Google.": {
      "en": "Unable to sign-in using Google.",
      "ar": "تعذر تسجيل الدخول باستخدام Google."
    },
    "Account email or password is invalid.": {
      "en": "Account email or password is invalid.",
      "ar": "البريد الإلكتروني للحساب أو كلمة المرور غير صحيحة"
    },
    "Continue": {
      "en": "Continue",
      "ar": "استمر"
    },
    "Register": {
        "en": "Register",
        "ar": "تسجيل"
      },
      "First Name": {
        "en": "First Name",
        "ar": "الاسم الأول"
      },
      "Last Name": {
        "en": "Last Name",
        "ar": "الاسم الأخير"
      },
      "Phone": {
        "en": "Phone",
        "ar": "رقم الجوال"
      },
      "Nationality": {
        "en": "Nationality",
        "ar": "الجنسية"
      },
      "Register Now": {
        "en": "Register Now",
        "ar": "سجل الان"
      },
      "Already have an account? ": {
        "en": "Already have an account? ",
        "ar": "هل لديك حساب؟"
      },
      "Login": {
        "en": "Login",
        "ar": "تسجيل الدخول"
      },
      "Your first name is required": {
        "en": "Your first name is required",
        "ar": "الاسم الأول مطلوب"
      },
      "Your last name is required": {
        "en": "Your last name is required",
        "ar": "اسم العائلة مطلوب"
      },
      "Only use letters and spaces": {
        "en": "Only use letters and spaces",
        "ar": "استخدم الحروف والمسافات فقط"
      },
      "Please provide a valid phone number": {
        "en": "Please provide a valid phone number",
        "ar": "الرجاء تقديم رقم جوال صالح"
      },
      "Please select your nationality": {
        "en": "Please select your nationality",
        "ar": "الرجاء تحديد الجنسية"
      },
      "Password must have at minimum 6 charaters": {
        "en": "Password must have at minimum 6 charaters",
        "ar": "يجب أن تحتوي كلمة المرور على 6 أحرف على الأقل"
      },
      "Select Nationality": {
        "en": "Select Nationality",
        "ar": "اختر الجنسية"
      },
      "User account already exists.": {
        "en": "User account already exists.",
        "ar": "حساب المستخدم موجود بالفعل."
      },
      "Please provide a valid password": {
        "en": "Please provide a valid password",
        "ar": "الرجاء إدخال كلمة مرور صالحة"
      },
      "Passwords do not match. Please try again!": {
        "en": "Passwords do not match. Please try again!",
        "ar": "كلمة المرور غير مطابقة. حاول مرة اخرى!"
      },
      "Your password has been updated. Please sign-in to continue.": {
        "en": "Your password has been updated. Please sign-in to continue.",
        "ar": "لقد تم تحديث كلمة المرور الخاصة بك. من فضلك سجل دخولك للمتابعة."
      },
      "Something went wrong. Please try again!": {
        "en": "Something went wrong. Please try again!",
        "ar": "هناك خطأ ما. حاول مرة اخرى!"
      },
      "Update Password": {
        "en": "Update Password",
        "ar": "تحديث كلمة المرور"
      },
      "Sign-In": {
        "en": "Sign-In",
        "ar": "تسجيل الدخول"
      },
      "Reset Password": {
        "en": "Reset Password",
        "ar": "إعادة تعيين كلمة المرور"
      },
      "Choose a new password for your ClickToys account.": {
        "en": "Choose a new password for your ClickToys account.",
        "ar": "اختر كلمة مرور جديدة لحساب كليك تويزالخاص بك."
      },
      "New Password": {
        "en": "New Password",
        "ar": "كلمة المرور الجديدة"
      },
      "Confirm Password": {
        "en": "Confirm Password",
        "ar": "تأكيد كلمة المرور"
      },
      "Your email is not associated with any account.": {
        "en": "Your email is not associated with any account.",
        "ar": "بريدك الإلكتروني غير مرتبط بأي حساب."
      },
      "A recovery link was sent to": {
        "en": "A recovery link was sent to",
        "ar": "تم إرسال الرابط  إلى"
      },
      "Please use the link to successfully reset your password.": {
        "en": "Please use the link to successfully reset your password.",
        "ar": "الرجاء استخدام الرابط لإعادة تعيين كلمة المرور الخاصة بك بنجاح."
      },
      "Please provide your account email address to reset your password": {
        "en": "Please provide your account email address to reset your password",
        "ar": "يرجى تقديم عنوان البريد الإلكتروني لحسابك لإعادة تعيين كلمة المرور الخاصة بك"
      },
      "Email Address": {
        "en": "Email Address",
        "ar": "عنوان البريد الالكترونى"
      },
      "We have sent a four digit OTP to your provided mobile number ********":{
        "en": "We have sent a four digit OTP to your provided mobile number ********",
        "ar": "لقد أرسلنا كلمة المرور لمرة واحدة (OTP) المكونة من أربعة أرقام إلى رقم هاتفك المحمول المقدم ********"
      },
      "Please enter it below to continue": {
        "en": "Please enter it below to continue",
        "ar": "يرجى إدخاله أدناه للمتابعة"
      },
      "Request OTP in": {
        "en": "Request OTP in",
        "ar": "طلب كلمة المرور لمرة واحدة (OTP)"
      },
      "seconds": {
        "en": "seconds",
        "ar": "ثوانٍ"
      },
      "Didn't Receive an OTP?": {
        "en": "Didn't Receive an OTP?",
        "ar": "لم تستلم كلمة المرور لمرة واحدة (OTP)؟"
      },
      "Request Now": {
        "en": "Request Now",
        "ar": "اطلب الآن"
      },
      "Confirm New Password": {
        "en": "Confirm New Password",
        "ar": "تأكيد كلمة المرور الجديدة"
      },
      "Account Locked": {
        "en": "Account Locked",
        "ar": "تم قفل الحساب"
      },
      "Too Many Attempts Were Made": {
        "en": "Too Many Attempts Were Made",
        "ar": "تم إجراء العديد من المحاولات"
      },
      "This field is required": {
        "en": "This field is required",
        "ar": "هذا الحقل مطلوب"
      },
      "Your account has been locked because too many attempts were made. Please contact support@clicktoys.com for assistance.": {
        "en": "Your account has been locked because too many attempts were made. Please contact support@clicktoys.com for assistance.",
        "ar": "تم قفل حسابك بسبب العديد من المحاولات. يرجى التواصل مع support@clicktoys.com للمساعدة."
      },
      "Your four digit OTP is invalid": {
        "en": "Your four digit OTP is invalid",
        "ar": "كود OTP المكون من أربعة أرقام غير صالح"
      },
      "Passwords do not match": {
        "en": "Passwords do not match",
        "ar": "كلمات المرور غير متطابقة"
      },
      "Email / Phone": {
        "en": "Email / Phone",
        "ar": "البريد الإلكتروني / الهاتف"
      },
      "Account email or phone or password is invalid.": {
        "en": "Account email or phone or password is invalid.",
        "ar": "البريد الإلكتروني أو رقم الهاتف أو كلمة المرور غير صالحة."
      },
      "Didn't Recieve an OTP?": {
        "en": "Didn't Recieve an OTP?",
        "ar": "لم تتلقَ رمز التحقق؟"
      },
      "Enter Email / Phone": {
        "en": "Enter Email / Phone",
        "ar": "أدخل البريد الإلكتروني / الهاتف"
      },
      "Please enter your four digit OTP": {
        "en": "Please enter your four digit OTP",
        "ar": "من فضلك أدخل رمز التحقق المكون من أربعة أرقام."
      },
      " You entered the password that is the same as one of the last passwords you used. Please create a new password.": {
        "en": "You entered the password that is the same as one of the last passwords you used. Please create a new password.",
        "ar": "لقد أدخلت كلمة مرور تتطابق مع إحدى كلمات المرور التي استخدمتها سابقاً. يرجى إنشاء كلمة مرور جديدة."
      },
  }
  export default translations